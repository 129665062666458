import {Language} from "../../domain/Language";
import {PackageAccessType} from "../../domain/Package";

interface ApplicationConfig {
    title: string;
    banner: string;
    mimeFilter?: MimeFilter | null
    checksumAlgo?: string;
    archiveEnabled?: boolean;
    domains?: string[];
    adfsConfig?: AdfsSettings[];
    oidcConfig?: OidcSettings[];
    baseDownloadUri?: string;
    maxFileSize: number;
    maxPackageSize: number;
    maxPackageFileCount: number;
    cleanExpiration: number;
    briefcaseCleanExpiration: number;
    minCleanExpiration: number;
    maxCleanExpiration: number;
    maxFileSizeInArchive: number;
    defaultLanguage: string;
    mailPrimaryLanguage: string;
    mailSecondaryLanguage?: string;
    homePageContent: HomePageContent;
    allowDownloadCleanFilesFromQuaratine?: boolean;
    allowAnonymousUpload?: boolean;
    packageSendDefaultOption: PackageAccessType;
    allowAddFilesToPackage?: boolean;
    allowDeleteFilesFromPackage?: boolean;
    rememberPasswordDuration?: number;
    encryptionAtRestEnabled?: boolean;
    encryptionPasswordAnonymousEnabled?: boolean;
    encryptionPasswordUsersMode?: string;
    integrityCheckForUsers?: boolean;
    passwordRequirements?: any;
    languages?: Language[];
    packagePasswordRequirements?: any;
    anonymousUploadDownloadPasswordRequired?: boolean;
    userUploadDownloadPasswordRequired?: boolean;
    mfaRequiredForLocalUsers?: boolean;
    mfaRequiredForAdUsers?: boolean;
    mfaRequiredForAdfsUsers?: boolean;
    sendPackageWithoutApproval?: string;
    sendPackageToYourself?: string;
    allowDownloadSentFiles?: string;
    packageLimitAccessAllowed?: boolean;
    packageLimitAccessRequired?: boolean;
    packageLimitAccessCount?: number;
    packageLimitAccessCountMax?: number;
    packageLimitAccessCountMin?: number;
    termsAndDisclaimerHead__EN?: string;
    termsAndDisclaimerBody__EN?: string;
    termsAndDisclaimerHead__CS?: string;
    termsAndDisclaimerBody__CS?: string;
    termsAndDisclaimerHead__HU?: string;
    termsAndDisclaimerBody__HU?: string;
    termsAndDisclaimerHead__SK?: string;
    termsAndDisclaimerBody__SK?: string;
    termsAndDisclaimer?: string;
    rememberTermsAndDisclaimerFor?: number;
    briefcase?: "PERMISSION_BASED" | "YES" | "NO" | undefined;
    cooperativePackages?: "PERMISSION_BASED" | "YES" | "NO" | undefined;
    allowUserUpload?: boolean;
    preferredApprover?: boolean;
    closedNetworkIsSet?: boolean;
    closedNetwork?: boolean;

}


interface MimeFilter {
    enabled: boolean,
    kind: "whitelist" | "blacklist",
    list: string[]
}

interface AdfsSettings {
    instance: string;
    clientId: string;
    redirectUri: string;
    tenant: string;
}

export interface OidcSettings {
    id: string;
    name: string;
    authUri: string;
    type: OidcVendor;
}

export enum OidcVendor {
    MS = "MS",
    GOOGLE = "GOOGLE",
    APPLE = "APPLE",
    GENERIC = "GENERIC",
}

export enum HomePageContent {
    UPLOAD_PACKAGE = "UPLOAD_PACKAGE",
    LOGIN = "LOGIN"
}

export default ApplicationConfig;
