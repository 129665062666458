import {ConfigProvider} from "antd";
import cs_CZ from "antd/lib/locale-provider/cs_CZ";
import en_US from "antd/lib/locale-provider/en_US";
import hu_HU from "antd/lib/locale-provider/hu_HU";
import sk_SK from "antd/lib/locale-provider/sk_SK";
import {observer} from "mobx-react"
import "moment/locale/cs";
import "moment/locale/hu";
import "moment/locale/sk";
import {useContext} from 'react';
import {RawIntlProvider} from "react-intl";
import {AppContextContext} from "../Contexts";
import {intl} from "../sal-ui/Intl";
import AnonymousHomePage from "./AnonymousHomePage";
import HomePage from "./HomePage";

function App() {
    const appContext = useContext(AppContextContext);

    return (
        <RawIntlProvider value={intl} key={appContext.language}>
            <ConfigProvider locale={getEffectiveLocale()} csp={appContext.cspConfig} iconPrefixCls={"anticon"}>
                {appContext.user ? <HomePage/> : <AnonymousHomePage/>}
            </ConfigProvider>
        </RawIntlProvider>
    )

    function getEffectiveLocale() {
        switch (appContext.language) {
            case 'cs':
                return cs_CZ;
            case 'sk':
                return sk_SK;
            case 'hu':
                return hu_HU;
            default:
                return en_US;
        }
    }
}

export default observer(App);
