import {
    BarsOutlined,
    CheckCircleFilled,
    CheckCircleOutlined,
    CloseCircleFilled,
    CloseCircleOutlined,
    ColumnHeightOutlined,
    DeleteFilled,
    DownloadOutlined,
    EditFilled,
    EditOutlined,
    ExclamationCircleFilled,
    EyeOutlined,
    FileAddOutlined,
    FilePdfFilled,
    FileUnknownFilled,
    FolderAddFilled,
    ForwardFilled,
    GlobalOutlined,
    InfoCircleFilled,
    LinkOutlined,
    LockFilled,
    MailFilled,
    PushpinFilled,
    PushpinOutlined,
    QuestionCircleFilled,
    SecurityScanFilled,
    ShareAltOutlined,
    StarFilled,
    StarOutlined,
    SyncOutlined,
    WarningFilled,
} from '@ant-design/icons';
import {Alert, Button, Card, Checkbox, Col, Drawer, Dropdown, Menu, message, Popconfirm, Row, Skeleton, Space, Table, Tag, Tooltip} from "antd";
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";
import Column from "antd/lib/table/Column";
import Cookies from "js-cookie";
import _ from "lodash";
import {MouseEvent, ReactNode, useContext, useEffect, useRef, useState} from "react";
import {AdalConfig, AuthenticationContext} from "react-adal";
import CopyToClipboard from 'react-copy-to-clipboard';
import {useHistory, useLocation} from 'react-router';
import {Link} from "react-router-dom";
import {AppContextContext, LocalStorageServiceContext, packageFileService, PackageServiceContext} from "../Contexts";
import {DownloadTokenInfo, isDownloadTokenInfoValid} from "../domain/DownloadTokenInfo";
import {InternalRecipient} from "../domain/InternalRecipient";
import {
    Approver,
    DetectionEngineType,
    DownloadCounter,
    EncryptionStatus,
    FileCheck,
    FileCheckResult,
    FileFlag,
    FileIntegrityResult,
    FinalFileCheckResult,
    Package,
    PackageAccessType,
    PackageCheckState,
    PackageDataIntegrity,
    PackageFile,
    PackageFlag,
    PackagePasswordType,
    PackageWorkflowState
} from "../domain/Package";
import {User, UserPermission} from "../domain/User";
import {useIntlMessage} from "../sal-ui/createIntlMessage";
import {intl} from "../sal-ui/Intl";
import TooltipParagraph from "../sal-ui/TooltipParagraph";
import LocalStorageNamespace from "../service/LocalStorageNamespace";
import {DownloadObjectType} from "../service/PackageService";
import DataUtils from "../utils/DataUtils";
import EventSourceNotifier from "../utils/EventSourceNotifier";
import {downloadFileViaAnchor} from "../utils/FileUtils";
import FormatUtils from "../utils/FormatUtils";
import {anonymousRoutes, anonymousRoutesMap} from "./AnonymousRoutes";
import ModalOperation from "./common/ModalOperation";
import {DocumentTitle} from "./DocumentTitle";
import DownloadFileLoginModal from "./DownloadFileLoginModal";
import DownloadPackagePasswordModal from "./DownloadPackagePasswordModal";
import FileModal from "./FileModal";
import OkLeftPopconfirm from "./OkLeftPopconfirm";
import PackageAccessConfirmRequired from "./PackageAccessConfirmRequired";
import styles from "./PackageDownload.module.css";
import PackageModal from "./PackageModal";
import PackagePasswordRequired from "./PackagePasswordRequired";
import {routesMap} from "./Routes";
import SetApproverModal from "./SetApproverModal";
import SmartTag from "./SmartTag";

enum ModelState {
    Loading,
    Loaded,
    PasswordRequired,
    AccessDenied,
    NotFound,
    TooManyRequests,
    LimitAccessConfirmRequired
}

function CheckboxPopConfirm(
    props: {
        title: string,
        onConfirm: (checkboxValue: boolean | undefined) => void,
        children: ReactNode,
        disabled?: boolean,
        checkboxEnabled?: boolean,
        checkboxLabel?: string,
        checkboxDefaultValue: boolean,
        icon?: ReactNode
    }) {

    const intlMessage = useIntlMessage("settings-encryption");
    const [checkboxValue, setCheckboxValue] = useState(props.checkboxDefaultValue);

    return (
        <>
            {
                props.disabled ? props.children :
                    <Popconfirm
                        title={
                            <>
                                <p>{props.title}</p>
                                {props.checkboxEnabled &&
                                    <Checkbox checked={checkboxValue} onChange={() => setCheckboxValue(!checkboxValue)}>{props.checkboxLabel}</Checkbox>
                                }
                            </>
                        }
                        okText={intlMessage("common.yes")}
                        cancelText={intlMessage("common.no")}
                        onConfirm={() => {
                            props.onConfirm(props.checkboxEnabled ? checkboxValue : undefined)
                        }}
                        icon={props.icon}>

                        {props.children}

                    </Popconfirm>
            }
        </>
    );
}

function PackageDownload() {
    const appContext = useContext(AppContextContext);
    const applicationConfig = appContext.applicationConfig;
    const packageService = useContext(PackageServiceContext);
    const localStorageService = useContext(LocalStorageServiceContext);
    const intlMessage = useIntlMessage("package-detail");
    const location = useLocation();
    const history = useHistory();

    const [modelState, setModelState] = useState<ModelState>(ModelState.Loading);
    const [ownPackage, setOwnPackage] = useState<boolean>(false);
    const [recipientPackage, setRecipientPackage] = useState<boolean>(false);


    const loadPackageQueue = useRef<any>();
    const skipSse = useRef<boolean>(false);
    const [aPackage, setAPackage] = useState<Package>({
        workflowState: PackageWorkflowState.ACTIVE,
        checkState: PackageCheckState.UNKNOWN,
        internalRecipients: [],
        externalRecipients: [],
        fileList: [],
        deletedFileList: [],
        uncleanFileList: []
    });
    const [editedFile, setEditedFile] = useState<PackageFile>();
    const [packageRequiresPassword, setPackageRequiresPassword] = useState<boolean>(false);
    const [enteredInvalidPassword, setEnteredInvalidPassword] = useState<boolean>(false);
    const [serverErrorMessage, setServerErrorMessage] = useState<string>("");
    const [modal, setModal] = useState({visible: false, title: "", model: {}, operation: ModalOperation.Update});
    const [fileModal, setFileModal] = useState({visible: false, title: "", model: {}, operation: ModalOperation.Update});
    const [fileModalVisible, setFileModalVisible] = useState<boolean>(false);
    const [setApproverModal, setSetApproverModal] = useState({visible: false, title: "", model: {}, operation: ModalOperation.Update});

    const [reportsVisible, setReportsVisible] = useState<boolean>(false);
    const [allMimeTypesVisible, setAllMimeTypesVisible] = useState<boolean>(false);
    const [allMimeTypes, setAllMimeTypes] = useState<any>();
    const [activeFileCheck, setActiveFileCheck] = useState<FileCheck>();
    const [fileCheck, setFileCheck] = useState<FileCheck>();

    const forceUpdate = useForceUpdate();

    const [downloadFileModal, setDownloadFileModal] = useState<{ visible: boolean, url: string, objectId: string, objectType: DownloadObjectType, packageDownloadToken?: string }>({
        visible: false,
        url: "",
        objectId: "",
        objectType: DownloadObjectType.FILE
    });

    const [packagePasswordModal, setPackagePasswordModal] = useState<{ visible: boolean, packageId: string, onDownloadTokenAcquired: (token: DownloadTokenInfo, rememberPassword: boolean) => void }>({
        visible: false,
        packageId: "",
        onDownloadTokenAcquired: () => {
            // no-op
        }
    });

    const downloadTokenInfo = useRef<DownloadTokenInfo>();
    const limitAccessToken = useRef<string>();

    useEffect(() => {
        const notifier = new EventSourceNotifier({
                filters: [
                    {name: 'PackageUpdatedNotification', id: packageId}
                ],
                onMessage: (event) => {
                    if (skipSse.current) return;

                    // hodne udalosti spusti load jen jednou
                    if (loadPackageQueue.current !== undefined) {
                        clearTimeout(loadPackageQueue.current);
                    }

                    loadPackageQueue.current = setTimeout(loadPackage, 500);
                }
            }
        );

        notifier.open();

        if (appContext.user && appContext.user.mfaEnforceRequirement(applicationConfig!)) {
            // routesMap neni pozite schvalne ... shazuje apliakci
            // nechame dokud to nevyresime
            setTimeout(history.push, 200, "/multi-factor-key/list");
        }

        return function () {
            appContext.disableLangSelector = false;
            notifier.close();
        };
    }, []);

    const matches = location.pathname.match(/\/packages\/([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})/i);

    const urlPath = matches![0];
    const packageId = matches![1];

    useEffect(() => {
        const storedDownloadTokenInfo = localStorageService.getIndexedItem(LocalStorageNamespace.PackagePassword, packageId);

        if (storedDownloadTokenInfo != null) {
            const parsedDownloadTokenInfo = JSON.parse(storedDownloadTokenInfo);

            downloadTokenInfo.current = (isDownloadTokenInfoValid(parsedDownloadTokenInfo)) ? parsedDownloadTokenInfo : undefined;
        } else {
            downloadTokenInfo.current = undefined;
        }

        loadPackage();
    }, [packageId]);

    const title = intlMessage("window-title");

    return (
        <DocumentTitle title={`${applicationConfig!.title}: ${title}`}>
            <div>
                <Skeleton loading={modelState === ModelState.Loading}>

                    {modelState === ModelState.Loaded ? renderLoaded() : ""}
                    {modelState === ModelState.AccessDenied ? renderAccessDenied() : ""}
                    {modelState === ModelState.NotFound ? renderNotFound() : ""}
                    {modelState === ModelState.TooManyRequests ? renderTooManyRequests() : ""}
                    {modelState === ModelState.PasswordRequired ?
                        <PackagePasswordRequired onSubmit={onPasswordSubmit}
                                                 enteredInvalidPassword={enteredInvalidPassword}
                                                 serverErrorMessage={serverErrorMessage}
                                                 passwordType={PackagePasswordType.Download}/> : ""}
                    {modelState === ModelState.LimitAccessConfirmRequired ?
                        <PackageAccessConfirmRequired onSubmit={onAccessConfirm} accessToken={limitAccessToken.current}/> : ""}
                </Skeleton>

                {modal.visible && aPackage &&
                    <PackageModal visible={modal.visible}
                                  title={modal.title}
                                  editMode={modal.operation === ModalOperation.Update}
                                  model={aPackage}
                                  onOk={onModalSave}
                                  onCancel={onModalCancel}
                    />}

                {fileModalVisible ?
                    <FileModal visible={fileModalVisible}
                               title={fileModal.title}
                               file={fileModal.model}
                               editMode={true}
                               onOk={() => onFileEditDone()}
                               onCancel={() => setFileModalVisible(false)}/> : ""}

                {setApproverModal.visible && aPackage ?
                    <SetApproverModal visible={setApproverModal.visible}
                                      title={setApproverModal.title}
                                      type={"PACKAGE"}
                                      package={aPackage}
                                      onOk={onSetApproverModalSave}
                                      onCancel={onSetApproverModalCancel}/> : ""}
            </div>
        </DocumentTitle>
    )

    function onSetApproverModalCancel() {
        resetSetApproverModalState();
    }

    function onSetApproverModalSave() {
        resetSetApproverModalState();

        loadPackage();
    }

    function onFileEditDone() {
        setFileModalVisible(false);
    }

    function onModalCancel() {
        resetModalState();
    }

    function onModalSave() {
        resetModalState();

        loadPackage();
    }

    function resetModalState() {
        setModal(prevState => {
            return ({
                ...prevState,
                visible: false,
                title: "",
                operation: ModalOperation.Update,
                model: {}
            });
        });
    }

    function resetSetApproverModalState() {
        setSetApproverModal(prevState => {
            return ({
                ...prevState,
                visible: false,
                title: "",
                operation: ModalOperation.Update,
                model: {}
            });
        });
    }

    function renderFileActions(packageFile: any) {

        let transitionWarning = "";

        if (aPackage.checkState === PackageCheckState.QUARANTINED && packageFile.state === "UNCLEAN") {
            if (aPackage.fileList?.filter((file: PackageFile) => file.state === "UNCLEAN").length === 1) {
                transitionWarning = " " + intlMessage("file-delete-unclean-to-clean");
            }
        }

        let deleteAction;
        let renameAction;

        if (!packageFile.deleted && canDeleteOrRenameFilesInPackage()) {
            deleteAction = <Menu.Item>
                <CheckboxPopConfirm
                    title={intlMessage("packages-inbox-outbox.confirm-file-delete", {fileName: packageFile.name}) + transitionWarning}
                    onConfirm={(deleteSafeCopy) => onDeleteFileConfirm(packageFile, deleteSafeCopy)}
                    checkboxDefaultValue={false}
                    checkboxLabel={intlMessage("delete-safe-copy")}
                    checkboxEnabled={packageFile.cdrCount! > 0}
                >

                    <Button type="default" className={"ant-btn-icon-only"}>
                        <DeleteFilled/>
                        {intlMessage("common.delete")}
                    </Button>
                </CheckboxPopConfirm>
            </Menu.Item>
        }

        if (canDeleteOrRenameFilesInPackage()) {
            renameAction = <Menu.Item>
                <Button type="default" className={"ant-btn-icon-only"} onClick={() => onEditFile(packageFile)}>
                    <EditOutlined/>
                    {intlMessage("packages-detail.edit-filename")}
                </Button>
            </Menu.Item>
        }

        const menu = <Menu className={"more-actions"}>
            {renameAction}
            {deleteAction}
        </Menu>;

        const anyAction = deleteAction || renameAction;

        if (anyAction) {
            return (
                <Dropdown overlay={menu} trigger={['click']}>
                    <a className="ant-dropdown-link ant-btn ant-btn-icon-only ant-btn-default" href="#" title={intlMessage("common.actions")}>
                        <BarsOutlined style={{verticalAlign: 'middle'}}/>
                    </a>
                </Dropdown>
            );
        }
        return "";
    }

    function renderAllMimeTypes() {

        const setOfTypes = allMimeTypes
            ?.map((value: any) => value.type)
            .reduce((acc: any, value: any) => acc.includes(value) ? acc : acc.concat(value), []);

        const detections: any = activeFileCheck?.detectionName?.replace("=", " - ").split(",");

        return (
            <div style={{marginTop: -16}}>
                <Table dataSource={detections} rowKey={(record) => record} pagination={false}
                       className={styles['detail-heading']}
                       showSorterTooltip={false}
                       title={() => intlMessage("package-detail.mime-blocked-files")}
                       size="small" style={{marginTop: 8, marginBottom: 8}}>
                    <Column dataIndex={""} title={intlMessage("package-detail.mime-blocked-files.file__mime")}/>
                </Table>

                <Table dataSource={setOfTypes} rowKey={(record) => record} pagination={false}
                       className={styles['detail-heading']}
                       showSorterTooltip={false}
                       title={() => intlMessage("package-detail.all-mime-types.mime-list")}
                       size="small" style={{marginTop: 8, marginBottom: 8}}>
                    <Column dataIndex={""} title={intlMessage("package-detail.file.mimeType")}/>
                </Table>

                <Table dataSource={allMimeTypes} rowKey="name" pagination={false}
                       className={styles['detail-heading']}
                       showSorterTooltip={false}
                       title={() => intlMessage("package-detail.all-mime-types.object-list")}
                       size="small" style={{marginTop: 8, marginBottom: 8}}>
                    <Column dataIndex="name" title={intlMessage("package-detail.file.name")}/>
                    <Column dataIndex="type" title={intlMessage("package-detail.file.mimeType")}/>
                </Table>
            </div>
        )
    }

    function packageApproved() {
        return !aPackage.approveRequired || (aPackage.approveRequired && aPackage.approved);
    }

    function renderActions() {
        if (!aPackage || !appContext.user) {
            return "";
        }

        let addRecipientAction;
        let editAction;
        let deleteAction;
        let forwardAction;
        let addFilesAction;
        let approveAction;
        let disapproveAction;
        let resendNotificationsAction;
        let setApproversAction;
        let setPersistentAction;

        if (canAddFilesToPackage()) {
            addFilesAction = <Menu.Item>
                <Button
                    onClick={() => {
                        localStorageService.setIndexedItem(LocalStorageNamespace.PackageDetail, packageId, JSON.stringify(aPackage));
                        history.push("/package/" + aPackage.id! + "/add-files");
                    }}
                    type="default"
                    className={"ant-btn-icon-only"}>
                    <FileAddOutlined/>
                    {intlMessage("common.add-files-to-package")}
                </Button>
            </Menu.Item>
        }

        // own packages
        // outbox
        if (ownPackage) {
            // active and clean or scan running
            if (packageApproved() && aPackage.workflowState === PackageWorkflowState.ACTIVE && (aPackage.checkState === PackageCheckState.CLEAN || aPackage.checkState === PackageCheckState.UNKNOWN)) {
                if (appContext.user.hasPermission(UserPermission.SEND_PACKAGE) && aPackage.accessType !== PackageAccessType.BRIEFCASE) {
                    addRecipientAction = <Menu.Item>
                        <Button onClick={(e: any) => onAddRecipient(aPackage, e)}
                                type="default"
                                className={"ant-btn-icon-only"}>
                            <MailFilled/>
                            {intlMessage("common.add-recipients")}
                        </Button>
                    </Menu.Item>;
                }

                editAction = <Menu.Item>
                    <Button onClick={(e: any) => onEdit(aPackage, e)}
                            type="default"
                            className={"ant-btn-icon-only"}>
                        <EditFilled/>
                        {intlMessage("common.edit")}
                    </Button>
                </Menu.Item>;

                if (aPackage.accessType !== PackageAccessType.BRIEFCASE) {
                    resendNotificationsAction = <Menu.Item>
                        <OkLeftPopconfirm
                            title={intlMessage("packages-inbox-outbox.confirm-resend-notifications", {packageName: aPackage.name ? aPackage.name : aPackage.id})}
                            onConfirm={() => onResendNotificationsConfirm(aPackage)}
                            okText={intlMessage("common.yes")}
                            cancelText={intlMessage("common.no")}>

                            <Button danger={true} className={"ant-btn-icon-only"}>
                                <MailFilled/>
                                {intlMessage("packages-inbox-outbox.resend-notifications")}
                            </Button>
                        </OkLeftPopconfirm>
                    </Menu.Item>
                }
            }

            if (packageApproved() &&
                (aPackage.workflowState === PackageWorkflowState.ACTIVE &&
                    (aPackage.checkState === PackageCheckState.CLEAN || aPackage.checkState === PackageCheckState.UNKNOWN)) ||
                aPackage.workflowState === PackageWorkflowState.REQUESTED) {

                deleteAction = <Menu.Item>
                    <OkLeftPopconfirm
                        title={intlMessage("packages-inbox-outbox.confirm-delete", {packageName: aPackage.name ? aPackage.name : aPackage.id})}
                        onConfirm={() => onDeleteConfirm(aPackage)}
                        okText={intlMessage("common.yes")}
                        cancelText={intlMessage("common.no")}>
                        <Button type="default" className={"ant-btn-icon-only"}>
                            <DeleteFilled/>
                            {intlMessage("common.delete")}
                        </Button>
                    </OkLeftPopconfirm>
                </Menu.Item>;

            }


        } else {
            // inbox
            if (packageApproved() &&
                (ownPackage || recipientPackage) &&
                aPackage.workflowState === PackageWorkflowState.ACTIVE && aPackage.checkState === PackageCheckState.CLEAN &&
                aPackage.accessType !== PackageAccessType.BRIEFCASE
            ) {
                deleteAction = <Menu.Item>
                    <OkLeftPopconfirm
                        title={intlMessage("packages-inbox-outbox.confirm-delete", {packageName: aPackage.name ? aPackage.name : aPackage.id})}
                        onConfirm={() => onRemoveRecipientConfirm(aPackage)}
                        okText={intlMessage("common.yes")}
                        cancelText={intlMessage("common.no")}>
                        <Button type="default" className={"ant-btn-icon-only"}>
                            <DeleteFilled/>
                            {intlMessage("common.delete")}
                        </Button>
                    </OkLeftPopconfirm>
                </Menu.Item>;

            }

            // inbox - vyzvy
            if (aPackage.workflowState === PackageWorkflowState.REQUESTED && recipientPackage) {
                deleteAction = <Menu.Item>
                    <OkLeftPopconfirm
                        title={intlMessage("packages-inbox-outbox.confirm-delete", {packageName: aPackage.name ? aPackage.name : aPackage.id})}
                        onConfirm={() => onDeleteConfirm(aPackage)}
                        okText={intlMessage("common.yes")}
                        cancelText={intlMessage("common.no")}>
                        <Button type="default" className={"ant-btn-icon-only"}>
                            <DeleteFilled/>
                            {intlMessage("common.delete")}
                        </Button>
                    </OkLeftPopconfirm>
                </Menu.Item>;

                resendNotificationsAction = <Menu.Item>
                    <OkLeftPopconfirm
                        title={intlMessage("packages-inbox-outbox.confirm-resend-notifications", {packageName: aPackage.name ? aPackage.name : aPackage.id})}
                        onConfirm={() => onResendNotificationsConfirm(aPackage)}
                        okText={intlMessage("common.yes")}
                        cancelText={intlMessage("common.no")}>

                        <Button danger={true} className={"ant-btn-icon-only"}>
                            <MailFilled/>
                            {intlMessage("packages-inbox-outbox.resend-notifications")}
                        </Button>
                    </OkLeftPopconfirm>
                </Menu.Item>

            }
        }

        // active clean
        if (packageApproved() &&
            aPackage.workflowState === PackageWorkflowState.ACTIVE && (aPackage.checkState === PackageCheckState.CLEAN) &&
            (ownPackage || recipientPackage)) {
            if (appContext.user.hasPermission(UserPermission.SEND_PACKAGE) &&
                aPackage.activeFileCount! > 0 &&
                aPackage.limitAccessCount === 0) {
                forwardAction = <Menu.Item>
                    <Button
                        onClick={() => history.push("/package-forward/" + aPackage.id!)}
                        type="default"
                        className={"ant-btn-icon-only"}>
                        <ForwardFilled/>
                        {intlMessage("common.forward-package")}
                    </Button>
                </Menu.Item>
            }
        }

        if (aPackage.approveRequired && aPackage.canBeApproved) {
            if (!aPackage.approved) {
                approveAction = <Menu.Item>
                    <OkLeftPopconfirm
                        title={intlMessage("packages-inbox-outbox.confirm-approve", {packageName: aPackage.name ? aPackage.name : aPackage.id})}
                        onConfirm={() => onApproveConfirm(aPackage)}
                        okText={intlMessage("common.yes")}
                        cancelText={intlMessage("common.no")}>
                        <Button type="default" className={"ant-btn-icon-only"}>
                            <CheckCircleOutlined/>
                            {intlMessage("common.approve")}
                        </Button>
                    </OkLeftPopconfirm>
                </Menu.Item>;
            }
            if (!aPackage.disapproved) {
                disapproveAction = <Menu.Item>
                    <OkLeftPopconfirm
                        title={intlMessage("packages-inbox-outbox.confirm-disapprove", {packageName: aPackage.name ? aPackage.name : aPackage.id})}
                        onConfirm={() => onDisapproveConfirm(aPackage)}
                        okText={intlMessage("common.yes")}
                        cancelText={intlMessage("common.no")}>
                        <Button type="default" className={"ant-btn-icon-only"}>
                            <CloseCircleOutlined/>
                            {intlMessage("common.disapprove")}
                        </Button>
                    </OkLeftPopconfirm>
                </Menu.Item>;
            }


        }

        if (aPackage.canSetApprover) {
            setApproversAction = <Menu.Item>
                <Button onClick={() => onSetApprovers(aPackage)}
                        type="default"
                        className={"ant-btn-icon-only"}>
                    <MailFilled/>
                    {intlMessage("package-modal.set-approver.title")}
                </Button>
            </Menu.Item>;
        }

        if (ownPackage && aPackage.workflowState === PackageWorkflowState.ACTIVE && aPackage.checkState === PackageCheckState.CLEAN &&
            appContext.user?.hasPermission(UserPermission.SET_PACKAGE_PERSISTENT)) {
            if (aPackage.persistent) {
                setPersistentAction =
                    <Menu.Item>
                        <OkLeftPopconfirm
                            title={intlMessage("packages-list.confirm-set-temporary", {packageName: aPackage.name ? aPackage.name : aPackage.id})}
                            onConfirm={() => onUnsetPersistentPackageConfirm(aPackage)}
                            okText={intlMessage("common.yes")}
                            cancelText={intlMessage("common.no")}>
                            <Button type="default" className={"ant-btn-icon-only"}>
                                <PushpinFilled/>
                                {intlMessage("package.set-temporary")}
                            </Button>
                        </OkLeftPopconfirm>
                    </Menu.Item>
            } else {
                setPersistentAction =
                    <Menu.Item>
                        <OkLeftPopconfirm
                            title={intlMessage("packages-list.confirm-set-persistent", {packageName: aPackage.name ? aPackage.name : aPackage.id})}
                            onConfirm={() => onSetPersistentPackageConfirm(aPackage)}
                            okText={intlMessage("common.yes")}
                            cancelText={intlMessage("common.no")}>
                            <Button type="default" className={"ant-btn-icon-only"}>
                                <PushpinOutlined/>
                                {intlMessage("package.set-persistent")}
                            </Button>
                        </OkLeftPopconfirm>
                    </Menu.Item>
            }
        }

        const menu = <Menu className={"more-actions"}>
            {forwardAction}
            {addRecipientAction}
            {resendNotificationsAction}
            {addFilesAction}
            {editAction}
            {approveAction}
            {disapproveAction}
            {setApproversAction}
            {setPersistentAction}
            {deleteAction}
        </Menu>;

        const anyAction = forwardAction || addRecipientAction || editAction || deleteAction || addFilesAction || approveAction ||
            disapproveAction || resendNotificationsAction || setApproversAction || setPersistentAction;

        if (anyAction) {
            return (
                <Dropdown overlay={menu} trigger={['click']}>
                    <a className="ant-dropdown-link ant-btn ant-btn-icon-only ant-btn-default" href="#" title={intlMessage("common.actions")}>

                        <BarsOutlined style={{verticalAlign: 'middle'}}/>
                        &nbsp;
                        {intlMessage("common.action")}
                    </a>
                </Dropdown>
            );
        }
        return "";
    }

    function onSetPersistentPackageConfirm(record: Package) {
        packageService.setPersistentPackage(record)
            .then(() => {
                message.success(intlMessage("package.marked-as-persistent", {packageName: record.name ? record.name : record.id}));
                loadPackage();
            });
    }

    function onUnsetPersistentPackageConfirm(record: Package) {
        packageService.unsetPersistentPackage(record)
            .then(() => {
                message.success(intlMessage("package.marked-as-temporary", {packageName: record.name ? record.name : record.id}));
                loadPackage();
            });
    }

    function onResendNotificationsConfirm(record: Package) {
        packageService.resendNotifications(record)
            .then(() => {
                message.success(intlMessage('package.notifications-resent', {packageName: record.name ? record.name : record.id}));
                loadPackage();
            });
    }

    function onApproveConfirm(record: Package) {
        packageService.approve(record)
            .then(() => {
                message.success(intlMessage('packages-inbox-outbox.approved', {packageName: record.name ? record.name : record.id}));
                loadPackage();
            });
    }

    function onDisapproveConfirm(record: Package) {
        packageService.disapprove(record)
            .then(() => {
                message.success(intlMessage('packages-inbox-outbox.disapproved', {packageName: record.name ? record.name : record.id}));
                loadPackage();
            });
    }

    function onSetApprovers(record: Package) {
        setSetApproverModal(prevState => {
            return ({
                ...prevState,
                visible: true,
                title: intlMessage("package-modal.set-approver.title", {name: record.name ? record.name : record.id}),
                operation: ModalOperation.Add,
                model: record
            });
        });
    }

    // odstrani prihlaseneho uzivatele z prijemcu
    function onRemoveRecipientConfirm(record: Package) {
        packageService.removeRecipient(record)
            .then(() => {
                message.success(intlMessage('package.deleted', {packageName: record.name ? record.name : record.id}));

                history.goBack();
            });
    }

    function onEdit(record: Package, e?: React.MouseEvent) {
        e!.preventDefault();
        setModal(prevState => {
            return ({
                ...prevState,
                visible: true,
                title: intlMessage("package-edit.title", {name: record.name ? record.name : record.id}),
                operation: ModalOperation.Update,
                model: record
            });
        });
    }

    function onEditFile(record: PackageFile) {
        setFileModalVisible(true);
        setFileModal(prevState => {
            return ({
                ...prevState,
                visible: true,
                title: intlMessage("package-file-edit.title", {name: record.name ? record.name : record.id}),
                operation: ModalOperation.Update,
                model: record
            });
        });
    }

    function onAddRecipient(record: Package, e?: React.MouseEvent) {
        e!.preventDefault();

        setModal(prevState => {
            return ({
                ...prevState,
                visible: true,
                title: intlMessage("package-add-recipient.title", {name: record.name ? record.name : record.id}),
                operation: ModalOperation.Add,
                model: record
            });
        });
    }

    function onDeleteConfirm(record: Package) {
        skipSse.current = true;
        packageService.delete(record)
            .then(() => {
                message.success(intlMessage("package.deleted", {packageName: record.name ? record.name : record.id}));

                history.goBack();
            });
    }

    function onDeleteFileConfirm(record: PackageFile, deleteSafeCopy: boolean | undefined) {
        record.packageId = aPackage.id;

        packageFileService.delete(record, deleteSafeCopy)
            .then(() => {
                message.success(intlMessage("package.file-deleted", {fileName: record.name}));

                loadPackage();
            });
    }

    function renderUserFlags(pkg: Package) {

        if (!ownPackage && !recipientPackage) {
            return ("");
        }

        return (<>{pkg.userFlags?.flagged ?
            <a className={"button"} title={intlMessage("packages.unset-flag")} onClick={() => setFlagged(pkg, false)} style={{opacity: 1.0}}><StarFilled className={"filled"}/></a> :
            <a className={"button"} title={intlMessage("packages.set-flag")} onClick={() => setFlagged(pkg, true)}><StarOutlined className={"outlined"} style={{opacity: 0.5}}/></a>
        }
        </>);
    }

    function setFlagged(pkg: Package, value: boolean) {
        if (!pkg.userFlags) {
            pkg.userFlags = {flagged: false, viewed: false}
        }

        pkg.userFlags!.flagged = value;

        setTimeout(forceUpdate, 10)

        if (value) {
            packageService.setFlagged(pkg);
        } else {
            packageService.unsetFlagged(pkg);
        }
    }

    function renderTitle() {
        return (
            <div className={"package-flags"}>
                {renderUserFlags(aPackage)}

                {aPackage.persistent && <PushpinFilled className={"info"} title={intlMessage("persistent-package")}/>}

                {aPackage.passwordProtected ? <LockFilled title={intlMessage("password-protected")}/> : ""}

                {aPackage.flags!.map((flag) => {
                    const key = "" + aPackage.id + flag.toString();
                    switch (flag) {
                        case PackageFlag.ARCHIVED:
                            return <FolderAddFilled key={key} title={intlMessage("packages-inbox-outbox.flags." + flag)}/>
                        case PackageFlag.OVERSIZE:
                            return <ColumnHeightOutlined key={key} style={{color: "crimson"}} title={intlMessage("packages-inbox-outbox.flags." + flag)}/>;
                        case PackageFlag.ENCRYPTED_CONTENT:
                            return <FileUnknownFilled key={key} style={{color: "crimson"}} title={intlMessage("packages-inbox-outbox.flags." + flag)}/>;
                        case PackageFlag.FORWARDED:
                            return <ForwardFilled key={key} title={intlMessage("packages-inbox-outbox.flags." + flag)}/>;
                        case PackageFlag.COOPERATIVE:
                            return ownPackage && <ShareAltOutlined key={"" + aPackage.id + flag.toString()} className={"info"} title={intlMessage("packages-inbox-outbox.flags.COOPERATIVE")}/>;
                        case PackageFlag.DLP_MATCHED:
                        default:
                            return "";
                    }
                })}

                {aPackage.accessType === PackageAccessType.PUBLIC && aPackage.workflowState != PackageWorkflowState.REQUESTED && <GlobalOutlined className={"info"} title={intlMessage("package-detail.public-access")}/>}
            </div>
        );
    }

    function renderLoaded() {
        const pkg = aPackage || {};

        const downloadLink = window.location.origin + (pkg ? urlPath : "/packages/");
        const uploadLink = window.location.origin + (pkg ? "/package-requested/" + packageId : "/packages/");

        const renderRowClass = (file: PackageFile): string => {
            switch (file.state) {
                case "CLEAN":
                    return "info";
                case "UNCLEAN":
                    return "danger";
                default:
                    return "warning";
            }
        };

        const extra = renderTitle();
        const layout1 = {xs: 24, sm: 24, md: 10, lg: 10, xl: 10, xxl: 10};
        const layout2 = {xs: 24, sm: 24, md: 14, lg: 14, xl: 14, xxl: 14};

        return <>
            <h1 style={{display: "inline"}}>
                {intlMessage("title", {id: pkg.name ? pkg.name : pkg.id})}
            </h1>

            <span style={{float: "right"}} className={"package-actions"}>
                {renderActions()}
            </span>

            <Row gutter={16}>
                <Col {...layout1} >
                    {aPackage.accessType === "BRIEFCASE" ?
                        <Card title={intlMessage("owner-title")} type="inner" className="card-sparse-rows package-detail">
                            <Row>
                                <Col span={6}>{intlMessage("owner")}:</Col>
                                <Col span={18}>{renderSender()}</Col>
                            </Row>
                        </Card>
                        :
                        <Card title={intlMessage("sender-recipients-title")} type="inner"
                              className="card-sparse-rows package-detail">
                            <Row>
                                <Col span={6}>{intlMessage("sender")}:</Col>
                                <Col span={18}>{renderSender()}</Col>
                            </Row>
                            {appContext.user && (aPackage.externalRecipients || aPackage.internalRecipients?.some(value => !value.contributor)) &&
                                <Row>
                                    <Col span={6}>{intlMessage("recipients")}:</Col>
                                    <Col span={18}>{renderRecipients(false)}</Col>
                                </Row>
                            }
                            {appContext.user && aPackage.internalRecipients?.some(value => value.contributor) &&
                                <Row>
                                    <Col span={6}>{intlMessage("contributors")}:</Col>
                                    <Col span={18}>{renderRecipients(true)}</Col>
                                </Row>
                            }

                        </Card>
                    }
                    <Card title={intlMessage("package-detail.download-archive")} type="inner" className="card-sparse-rows package-detail">
                        <Row style={{marginTop: "20px"}}>
                            <Col span={24}>
                                {renderArchive()}
                            </Col>
                        </Row>
                    </Card>

                </Col>

                <Col  {...layout2} >
                    <Card title={intlMessage("general-info-title")} type="inner"
                          className="card-sparse-rows package-detail" extra={extra}>
                        <Row>
                            <Col span={6}>{intlMessage("state")}:</Col>
                            <Col span={18} style={{fontWeight: 600}}>{renderPackageState()}</Col>
                        </Row>
                        <Row>
                            <Col span={6}>{intlMessage("package-access-type")}:</Col>
                            <Col span={18} style={{fontWeight: 600}}>{renderPackageAccess()}</Col>
                        </Row>


                        {pkg.dataEncryptedWithPassword &&

                            <Row>
                                <Col span={6}>{intlMessage("encryption-status")}:</Col>
                                <Col span={18} style={{fontWeight: 600}}>{intlMessage("data-encrypted-with-password")}</Col>
                            </Row>
                        }
                        {aPackage.workflowState !== PackageWorkflowState.REQUESTED &&
                            <Row>
                                <Col span={6}>{intlMessage("package-integrity")}:</Col>
                                <Col span={18} style={{fontWeight: 600}}>{renderPackageIntegrity()}</Col>
                            </Row>
                        }

                        <Row>
                            <Col span={6}>{intlMessage("uploaded")}:</Col>
                            <Col span={18}>{FormatUtils.formatOnDateAtTime(intl, pkg.uploaded!)}</Col>
                        </Row>
                        <Row>
                            <Col span={6}>{intlMessage("id")}:</Col>
                            <Col span={18}>{pkg.id}</Col>
                        </Row>
                        <Row>
                            <Col span={6}>{intlMessage("total-size")}:</Col>
                            <Col span={18}>{FormatUtils.formatBytes(pkg.activeSize)}</Col>
                        </Row>

                        {pkg.passwordProtected &&
                            <Row>
                                <Col span={6}>{intlMessage("password-protected")}:</Col>
                                <Col span={18}>{pkg.passwordProtected ? intlMessage("common.yes") : intlMessage("common.no")}</Col>
                            </Row>
                        }

                        {(aPackage.limitAccessCount !== undefined && aPackage.limitAccessCount > 0) &&
                            <Row>
                                <Col span={6}>{intlMessage("package-detail.package-with-limited-access-count")}:</Col>
                                <Col span={18}>{(aPackage.limitAccessCount) ? (intlMessage("common.yes") + " (" + (intlMessage("package-detail.access-limit-count-usage", {usedCount: aPackage.accessCount, limitCount: aPackage.limitAccessCount})) + ")") : intlMessage("common.no")}</Col>
                            </Row>
                        }

                        <Row>
                            {pkg.workflowState === PackageWorkflowState.REQUESTED ?

                                <>
                                    <Col span={6}>{intlMessage("upload-link")}:</Col>
                                    <Col span={18}>
                                        {uploadLink}
                                        <CopyToClipboard text={uploadLink}
                                                         onCopy={() => message.info(intlMessage("link-copied"))}>
                                            <a>
                                                <LinkOutlined
                                                    title={intlMessage("copy-link-title")}
                                                    style={{fontSize: "16px", marginLeft: "6px"}}/>
                                            </a>
                                        </CopyToClipboard>
                                    </Col>
                                </> :
                                <>
                                    <Col span={6}>{intlMessage("download-link")}:</Col>
                                    < Col span={18}>
                                        {downloadLink}
                                        <CopyToClipboard text={downloadLink}
                                                         onCopy={() => message.info(intlMessage("link-copied"))}>
                                            <a>
                                                <LinkOutlined
                                                    title={intlMessage("copy-link-title")}
                                                    style={{fontSize: "16px", marginLeft: "6px"}}/>
                                            </a>
                                        </CopyToClipboard>
                                    </Col>
                                </>
                            }
                        </Row>

                        {!pkg.persistent &&
                            <p style={{marginTop: 8}}>{createNextWorkflowStepMessage()}</p>}
                    </Card>

                    {appContext.user && aPackage.approveRequired &&
                        <Card title={intlMessage("package-send-approve")} type="inner"
                              className="card-sparse-rows package-detail">
                            <>
                                {renderApproveState()}
                            </>
                        </Card>
                    }
                </Col>

            </Row>

            {pkg.noteFrom &&
                <Card className={"package-detail-note"}>
                    <div
                        className={"package-detail-note-label"}>{intlMessage('note' + (pkg.noteTo ? "-from" : ""))}:
                    </div>
                    {pkg.noteFrom && <div className={"package-detail-note-text"}>{pkg.noteFrom}</div>}
                </Card>
            }

            {pkg.noteTo &&
                <Card className={"package-detail-note"}>
                    <div className={"package-detail-note-label"}>{intlMessage("note-to")}:
                    </div>
                    {pkg.noteTo && <div className={"package-detail-note-text"}>{pkg.noteTo}</div>}
                </Card>
            }


            <h2 style={{display: 'inline-block', marginRight: 10}}>{intlMessage("package-detail.active-files")}</h2>

            { // pridavani souboru
                canAddFilesToPackage() &&

                <span style={{}} className={"package-actions"}>
                        <Button type="primary" icon={<FileAddOutlined/>} onClick={() => {
                            localStorageService.setIndexedItem(LocalStorageNamespace.PackageDetail, packageId, JSON.stringify(aPackage));
                            history.push("/package/" + aPackage.id! + "/add-files");
                        }}>{intlMessage("common.add")}</Button>
                    </span>
            }

            {(pkg.fileList && pkg.fileList.length > 0) &&
                <Table dataSource={pkg.fileList.filter(file => !file.flags?.includes(FileFlag.CDR) ||
                    file.flags?.includes(FileFlag.CDR) && !aPackage.fileList?.find(packageFile => packageFile.id === file.originalFile)
                )}
                       size="middle" showSorterTooltip={false} rowKey="id" pagination={false}
                       className={"package-detail-file-list"}
                       expandedRowRender={renderFileChecks}
                       expandIcon={renderTableExpandIcon}
                       rowClassName={renderRowClass}>
                    <Column dataIndex="name" title={intlMessage("package-detail.file.name")}
                            sorter={(a, b) => packageFileSorter(a.name, b.name)}
                            sortDirections={["descend", "ascend", "descend"]}
                            defaultSortOrder={"ascend"}
                            render={renderFileName}
                            className={"text-overflow max-width-300px"}/>


                    <Column dataIndex="state" title={intlMessage("package-detail.file.state")} width={90}
                            render={renderState}/>
                    <Column dataIndex="hash"
                            title={intlMessage("package-detail.file.integrity")}
                            render={renderFileIntegrity} width={"150px"}/>
                    <Column dataIndex="mimeType" title={intlMessage("package-detail.file.mimeType")}
                            className={"text-overflow max-width-200px"}
                            sorter={packageFileMimeSorter}
                            sortDirections={["ascend", "descend", "ascend"]}
                            render={renderFileMimeType}/>
                    <Column dataIndex="uploadedBy" title={intlMessage("package-detail.file.uploaded-by")} width={200}
                            render={renderUploadedBy}/>
                    <Column dataIndex="uploaded" title={intlMessage("package-detail.file.uploaded")} render={renderFileUploaded} width={"150px"}
                            sorter={(a, b) => packageFileSorter(a.uploaded, b.uploaded)}
                            sortDirections={["descend", "ascend", "descend"]}/>
                    <Column dataIndex="size" title={intlMessage("package-detail.file.size")} width={90}
                            sorter={(a: PackageFile, b: PackageFile) => packageFileSorter(a.size, b.size)}
                            sortDirections={["descend", "ascend", "descend"]}
                            render={renderSize}/>

                    {appContext.user &&

                        <Column dataIndex="downloadCounter"
                                title={intlMessage("package-detail.download-count")}
                                sorter={packageFileDownloadCountSorter}
                                sortDirections={["descend", "ascend", "descend"]}
                                align={"center"}
                                render={renderDownloadCounter}
                                width={"150px"}/>
                    }
                    {canDeleteOrRenameFilesInPackage() &&
                        <Column title={intlMessage("common.action")} render={renderFileActions}
                                width={"70px"}/>
                    }
                </Table>
            }

            {pkg.uncleanFileList && pkg.uncleanFileList.length > 0 &&
                <>
                    <h2>{intlMessage("package-detail.unclean-files")}</h2>
                    <Table dataSource={pkg.uncleanFileList.filter(file => !file.flags?.includes(FileFlag.CDR) ||
                        file.flags?.includes(FileFlag.CDR) && !aPackage.uncleanFileList?.find(packageFile => packageFile.id === file.originalFile)
                    )}
                           size="middle" showSorterTooltip={false} rowKey="id" pagination={false}
                           className={"package-detail-file-list"}
                           expandIcon={renderTableExpandIcon}
                           expandedRowRender={renderFileChecks}
                           rowClassName={renderRowClass}>
                        <Column dataIndex="name" title={intlMessage("package-detail.file.name")}
                                sorter={(a, b) => packageFileSorter(a.name, b.name)}
                                sortDirections={["descend", "ascend", "descend"]}
                                defaultSortOrder={"ascend"}
                                render={renderFileName}
                                className={"text-overflow max-width-300px"}/>
                        <Column dataIndex="state" title={intlMessage("package-detail.file.state")} width={90}
                                render={renderState}/>
                        <Column dataIndex="hash"
                                title={intlMessage("package-detail.file.integrity")}
                                render={renderFileIntegrity} width={"150px"}/>
                        <Column dataIndex="mimeType" title={intlMessage("package-detail.file.mimeType")} className={"text-overflow max-width-100px"}
                                sorter={packageFileMimeSorter}
                                sortDirections={["ascend", "descend", "ascend"]}
                                render={renderFileMimeType}/>
                        <Column dataIndex="uploaded" title={intlMessage("package-detail.file.uploaded")}
                                sorter={(a, b) => packageFileSorter(a.uploaded, b.uploaded)}
                                sortDirections={["descend", "ascend", "descend"]}
                                render={renderFileUploaded} width={"150px"}/>
                        <Column dataIndex="size" title={intlMessage("package-detail.file.size")} width={90}
                                sorter={(a: PackageFile, b: PackageFile) => packageFileSorter(a.size, b.size)}
                                sortDirections={["descend", "ascend", "descend"]}
                                render={renderSize}/>
                        {
                            appContext.user &&

                            <Column dataIndex="downloadCounter"
                                    title={intlMessage("package-detail.download-count")}
                                    sorter={packageFileDownloadCountSorter}
                                    sortDirections={["descend", "ascend", "descend"]}
                                    align={"center"}
                                    render={renderDownloadCounter}
                                    width={"150px"}/>
                        }
                    </Table>
                </>
            }

            {appContext.user && pkg.deletedFileList && pkg.deletedFileList.length > 0 &&
                <>
                    <h2>{intlMessage("package-detail.deleted-files")}</h2>
                    <Table dataSource={pkg.deletedFileList.filter(file => !file.flags?.includes(FileFlag.CDR) ||
                        file.flags?.includes(FileFlag.CDR) && !aPackage.deletedFileList?.find(packageFile => packageFile.id === file.originalFile)
                    )} size="middle" showSorterTooltip={false} rowKey="id" pagination={false}
                           className={"package-detail-file-list"}
                           expandIcon={renderTableExpandIcon}
                           expandedRowRender={renderFileChecks}
                           rowClassName={renderRowClass}>
                        <Column dataIndex="name" title={intlMessage("package-detail.file.name")}
                                sorter={(a, b) => packageFileSorter(a.name, b.name)}
                                sortDirections={["descend", "ascend", "descend"]}
                                defaultSortOrder={"ascend"}
                                render={renderFileName}
                                className={"text-overflow max-width-300px"}/>
                        <Column dataIndex="state" title={intlMessage("package-detail.file.state")} width={90}
                                render={renderState}/>
                        <Column dataIndex="hash"
                                title={intlMessage("package-detail.file.integrity")}
                                render={renderFileIntegrity} width={"150px"}/>
                        <Column dataIndex="mimeType" title={intlMessage("package-detail.file.mimeType")} className={"text-overflow max-width-100px"}
                                sorter={packageFileMimeSorter}
                                sortDirections={["ascend", "descend", "ascend"]}
                                render={renderFileMimeType}/>
                        <Column dataIndex="uploaded" title={intlMessage("package-detail.file.uploaded")}
                                sorter={(a, b) => packageFileSorter(a.uploaded, b.uploaded)}
                                sortDirections={["descend", "ascend", "descend"]}
                                render={renderFileUploaded} width={"150px"}/>
                        <Column dataIndex="deleted" title={intlMessage("package-detail.file.deleted")}
                                sorter={(a, b) => packageFileSorter(a.deleted, b.deleted)}
                                sortDirections={["descend", "ascend", "descend"]}
                                render={renderFileUploaded} width={"150px"}/>
                        <Column dataIndex="size" title={intlMessage("package-detail.file.size")} width={90}
                                sorter={(a: PackageFile, b: PackageFile) => packageFileSorter(a.size, b.size)}
                                sortDirections={["descend", "ascend", "descend"]}
                                render={renderSize}/>
                        {
                            appContext.user &&

                            <Column dataIndex="downloadCounter"
                                    title={intlMessage("package-detail.download-count")}
                                    sorter={packageFileDownloadCountSorter}
                                    sortDirections={["descend", "ascend", "descend"]}
                                    align={"center"}
                                    render={renderDownloadCounter}
                                    width={"150px"}/>
                        }
                    </Table>
                </>
            }

            <Drawer title={intlMessage("package-detail.reports-title")} width={800}
                    onClose={onClose} visible={reportsVisible} destroyOnClose={true}>
                {renderFileCheckReports()}
            </Drawer>

            <Drawer title={intlMessage("package-detail.all-mime-types.title")} width={800}
                    onClose={() => setAllMimeTypesVisible(false)} visible={allMimeTypesVisible} destroyOnClose={true}>
                {renderAllMimeTypes()}
            </Drawer>

            {
                downloadFileModal.visible &&

                <DownloadFileLoginModal
                    visible={downloadFileModal.visible}
                    onOk={onDownloadFileModalOk}
                    onCancel={onDownloadFileModalCancel}
                    url={downloadFileModal.url}
                    objectId={downloadFileModal.objectId}
                    objectType={downloadFileModal.objectType}/>
            }

            {
                packagePasswordModal.visible &&

                <DownloadPackagePasswordModal
                    visible={packagePasswordModal.visible}
                    packageId={packageId}
                    onDownloadTokenAcquired={packagePasswordModal.onDownloadTokenAcquired}
                    onOk={() => setPackagePasswordModal(prevState => ({...prevState, visible: false}))}
                    onCancel={() => setPackagePasswordModal(prevState => ({...prevState, visible: false}))}/>
            }

        </>;
    }

    function packageFileMimeSorter(prev: PackageFile, next: PackageFile) {
        if ((prev.mimeType ?? '') > (next.mimeType ?? '')) {
            return 1;
        } else {
            return (prev.mimeType ?? '') < (next.mimeType ?? '') ? -1 : 0;
        }
    }

    function renderState(text: string, file: PackageFile) {

        switch (text) {
            case "CLEAN":
                if (file.flags?.includes(FileFlag.CDR) || file.originalFile) {
                    return intlMessage("FileState.CDR");
                } else {
                    return intlMessage("FileState.CLEAN");
                }
            case "UNCLEAN":
                return intlMessage("FileState.UNCLEAN");
            case "UPLOADING":
                return intlMessage("FileState.UPLOADING");
            case "UPLOAD_CANCELED":
                return intlMessage("FileState.UPLOAD_CANCELED");
            case "WAITING":
                return intlMessage("FileState.WAITING");
            default:
                return intlMessage("FileState.UNKNOWN_STATE");
        }

    }

    function renderFileMimeType(mimeType: any, file: PackageFile) {
        return (
            <TooltipParagraph style={{maxWidth: "150px"}}>
                {mimeType}
            </TooltipParagraph>
        );
    }

    function renderTableExpandIcon(data: any) {

        const expanded = data.expanded;
        const onExpand = data.onExpand;
        const record = data.record;

        if ((!record.fileChecks || record.fileChecks.length == 0)) {
            const filePath = `/download-file/${record.id}`;

            if (isFileDownloadAllowed(record)) {
                return <a href={filePath}
                          onClick={e => {
                              e.preventDefault();

                              checkPackageAndLoginPasswordForDownload(e, filePath, record.id!, DownloadObjectType.FILE);
                          }}>
                    <DownloadOutlined/>
                </a>;
            } else {
                return <></>;
            }
        }

        return expanded ? (
            <button type="button" onClick={e => onExpand(record, e)} className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded" aria-label="Zabalit řádek" aria-expanded="true"></button>
        ) : (
            <button type="button" onClick={e => onExpand(record, e)} className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed" aria-label="Rozbalit řádek" aria-expanded="false"></button>
        )
    }

    function renderFileChecks(record: PackageFile) {

        const renderRowClass = (check: any): string => {
            switch (check.finalCheckResult) {
                case "CLEAN":
                case "NONE":
                    return "info";

                case "WARNING":
                    return "warning";

                case "UNCLEAN":
                default:
                    return "danger";
            }
        };

        // detail souboru a cdr schovane na zaklade diskuze a uvidime
        return (
            <>
                {false && renderFileDetail(record)}

                {false && record.cdrFiles && record.cdrFiles!.length > 0 &&
                    <>
                        <h3 className={styles["file-detail-title"]}>{intlMessage("package-detail.file.cdr")}</h3>
                        <Table dataSource={record.cdrFiles}
                               size="middle" showSorterTooltip={false} rowKey="id" pagination={false}
                               className={"package-detail-file-list"}
                        >
                            <Column dataIndex="name" title={intlMessage("package-detail.file.name")}
                                    sorter={(a, b) => packageFileSorter(a.name, b.name)}
                                    sortDirections={["descend", "ascend", "descend"]}
                                    defaultSortOrder={"ascend"}
                                    render={renderFileName}
                                    className={"text-overflow max-width-300px"}/>


                            <Column dataIndex="state" title={intlMessage("package-detail.file.state")} width={90}
                                    render={renderState}/>
                            <Column dataIndex="hash"
                                    title={intlMessage("package-detail.file.integrity")}
                                    render={renderFileIntegrity} width={"150px"}/>
                            <Column dataIndex="mimeType" title={intlMessage("package-detail.file.mimeType")}
                                    className={"text-overflow max-width-200px"}
                                    sorter={packageFileMimeSorter}
                                    sortDirections={["ascend", "descend", "ascend"]}
                                    render={renderFileMimeType}/>
                            <Column dataIndex="uploadedBy" title={intlMessage("package-detail.file.uploaded-by")} width={200}
                                    render={renderUploadedBy}/>
                            <Column dataIndex="uploaded" title={intlMessage("package-detail.file.uploaded")} render={renderFileUploaded} width={"150px"}
                                    sorter={(a, b) => packageFileSorter(a.uploaded, b.uploaded)}
                                    sortDirections={["descend", "ascend", "descend"]}/>
                            <Column dataIndex="size" title={intlMessage("package-detail.file.size")} width={90}
                                    sorter={(a: PackageFile, b: PackageFile) => packageFileSorter(a.size, b.size)}
                                    sortDirections={["descend", "ascend", "descend"]}
                                    render={renderSize}/>

                            {appContext.user &&

                                <Column dataIndex="downloadCounter"
                                        title={intlMessage("package-detail.download-count")}
                                        sorter={packageFileDownloadCountSorter}
                                        sortDirections={["descend", "ascend", "descend"]}
                                        align={"center"}
                                        render={renderDownloadCounter}
                                        width={"150px"}/>
                            }
                            {canDeleteOrRenameFilesInPackage() &&
                                <Column title={intlMessage("common.action")} render={renderFileActions}
                                        width={"70px"}/>
                            }
                        </Table>
                    </>
                }


                {record.fileChecks && record.fileChecks.length > 0 &&
                    <>
                        <h3 className={styles["file-detail-title"]}>{intlMessage("package-detail.file-check.results")}</h3>
                        <Table dataSource={record.fileChecks} rowKey="id" pagination={false} size="small"
                               showSorterTooltip={false}
                               rowClassName={renderRowClass}
                               onRow={onFileCheckRow}>
                            <Column dataIndex="checkStarted"
                                    title={intlMessage("package-detail.file-check.check-started")}
                                    render={date => FormatUtils.formatDate(date)} width="14em"/>
                            <Column dataIndex="checkFinished"
                                    title={intlMessage("package-detail.file-check.check-finished")}
                                    render={date => FormatUtils.formatDate(date)} width="14em"/>
                            <Column dataIndex={["detectionEngine", "type"]}
                                    title={intlMessage("package-detail.file-check.engine-type")}
                                    render={t => intlMessage('detection-engines.type-' + t)}/>
                            <Column dataIndex="checkResult"
                                    title={intlMessage("package-detail.file-check.check-result")}
                                    render={renderCheckResult}/>
                            <Column dataIndex="detectionName"
                                    title={intlMessage("package-detail.file-check.detection-name")}
                                    render={renderDetectionName} className={"text-overflow max-width-200px"}/>
                            <Column render={renderExtraFileCheckInfo}/>
                        </Table>
                    </>
                }
            </>
        )

    }

    function renderExtraFileCheckInfo(text: any, record: FileCheck) {

        const mimeExtraInfo = (
            <>
                {record.extraInfo?.mimeTypes && <SecurityScanFilled
                    title={intlMessage("package-detail.all-mime-types.title")}
                    onClick={() => {
                        setAllMimeTypesVisible(true);
                        setAllMimeTypes(record.extraInfo?.mimeTypes);
                    }
                    }
                />}
            </>
        );

        return (
            <>
                {
                    (record.detectionEngine?.type === 'MIME') && mimeExtraInfo
                }

                {(record.linkToDetail) ? <a href={record.linkToDetail} target="_blank" rel="noreferrer"><LinkOutlined style={{marginRight: 8}}/></a> : ""}

                {renderFileCheckReport(text, record)}
            </>
        );
    }

    function renderFileCheckReport(text: any, record: FileCheck) {
        if ((record.detectionEngine.type === DetectionEngineType.CHECKPOINT_SANDBLAST || record.detectionEngine.type === DetectionEngineType.CHECKPOINT_SANDBLAST_CLOUD || record.detectionEngine.type === DetectionEngineType.FORTISANDBOX) && record.fileCheckReports.length > 0) {
            return (
                <SecurityScanFilled
                    title={intlMessage("package-detail.file-check.reports")}
                    onClick={() => showReports(record)}/>
            );
        }
    }

    function renderFileCheckReports() {
        if (fileCheck?.fileCheckReports || fileCheck?.extraInfo) {

            // fileCheck.
            const extraInfo = fileCheck.extraInfo ? renderFileCheckExtraInfo(fileCheck.id, fileCheck.extraInfo) : null;

            return (<>
                {extraInfo &&
                    <div style={{paddingTop: "16px"}}>
                        <h3>{intlMessage("additional-information")}</h3>
                        {extraInfo}
                    </div>
                }
            </>);
        }
    }

    function renderFileCheckExtraInfo(id: any, extraInfo: FileCheck) {
        return (
            <Table dataSource={DataUtils.convertObjectToKeyValueArray(extraInfo)} rowKey={"key"} pagination={false}
                   showSorterTooltip={false}
                   size="small" showHeader={true}>
                <Column dataIndex="key" title={intlMessage("extra-info-key")}/>
                <Column dataIndex="value" title={intlMessage("extra-info-value")} className={"text-overflow max-width-500px"}/>
            </Table>
        )
    }

    function renderReportDownload(record: any) {
        return (
            <>
                <a href={"/api/user/download-file-check-report/" + record.id}>
                    <DownloadOutlined style={{marginRight: 8}}/>
                </a>

                {record.mimeType === "application/pdf" &&
                    <a target={"_blank"} rel={"noreferrer"} href={"/api/user/download-file-check-report/" + record.id + "?view=true"}>
                        <EyeOutlined style={{marginRight: 8}}/>
                    </a>
                }
            </>
        );
    }

    function onFileCheckRow(record: FileCheck) {
        if (record && record.fileCheckReports.length > 0) {
            return {onDoubleClick: () => showReports(record)};
        } else if (record && record.extraInfo?.mimeTypes) {
            return {
                onDoubleClick: () => {
                    setAllMimeTypesVisible(true);
                    setAllMimeTypes(record.extraInfo?.mimeTypes);
                    setActiveFileCheck(record);
                }
            };
        } else {
            return {};
        }
    }

    function showReports(record: FileCheck) {
        setReportsVisible(true);
        setFileCheck(record);
    }

    function onClose() {
        setReportsVisible(false);
    }

    function renderDetectionName(record: string) {
        return (
            <>
                {(record) ? record.split(',').map((value, index) =>
                    <div key={index}>{value}<br/></div>
                ) : null}
            </>
        )
    }

    function renderCheckResult(text: any, fileCheck: FileCheck) {
        if (text !== 'NONE') {
            return intlMessage('package-detail.file-check.result-type-' + text + (fileCheck.finalCheckResult === FinalFileCheckResult.WARNING ? "_WARNING" : ""));
        }
    }

    function renderPreferredApprovers(approvers: Approver[]) {
        return approvers.map((approver, index) => {
            return <span key={index}>{renderApproverName(approver)}{index < approvers.length - 1 ? ", " : ""}</span>
        });
    }

    function renderApproveState() {
        if (appContext.user && aPackage.approveRequired) {

            const approver = (aPackage.approved || aPackage.disapproved) ? renderApproverName(aPackage.approverAdmin ? aPackage.approverAdmin : aPackage.approverUser) : ""

            let approveState = <><QuestionCircleFilled className={"color-orange"}/><b>{intlMessage("waiting-for-approve")}</b></>;
            if (aPackage.approved) {
                approveState = <><CheckCircleFilled className={"color-green"}/><b>{intlMessage("approved")}</b>-{approver}</>
            }
            if (aPackage.disapproved) {
                approveState = <><CloseCircleFilled className={"color-red"}/><b>{intlMessage("disapproved")}</b>-{approver}</>
            }

            return (
                <>
                    <Row>
                        <Col span={6}>{intlMessage("approve-state")}:</Col>
                        <Col span={18}><Space>{approveState}</Space></Col>
                    </Row>

                    {aPackage.approvers &&
                        <Row>
                            <Col span={6}>{intlMessage("preferred-approvers")}:</Col>
                            <Col span={18}>{renderPreferredApprovers(aPackage.approvers)}</Col>
                        </Row>
                    }

                    {aPackage.approved &&
                        <Row>
                            <Col span={6}>{intlMessage("approved")}:</Col>
                            <Col span={18}>{FormatUtils.formatOnDateAtTime(intl, aPackage.approved)} - {renderApproverName(aPackage.approverAdmin ? aPackage.approverAdmin : aPackage.approverUser)}</Col>
                        </Row>
                    }
                    {aPackage.disapproved &&
                        <Row>
                            <Col span={6}>{intlMessage("disapproved")}:</Col>
                            <Col span={18}>{FormatUtils.formatOnDateAtTime(intl, aPackage.disapproved)} - {renderApproverName(aPackage.approverAdmin ? aPackage.approverAdmin : aPackage.approverUser)}</Col>
                        </Row>
                    }
                </>
            );
        } else {
            return "";
        }
    }

    function integrityCheckAllowed(): boolean {
        if (aPackage.encryptionStatus === EncryptionStatus.ENCRYPTION_IN_PROGRESS || aPackage.encryptionStatus === EncryptionStatus.DECRYPTION_IN_PROGRESS) {
            return false;
        }

        if (appContext.applicationConfig?.integrityCheckForUsers && aPackage.workflowState === PackageWorkflowState.ACTIVE) {
            return true;
        } else {
            return false;
        }
    }

    function renderFileIntegrity(record: Package, file: PackageFile) {

        let result: any;

        file.packageId = aPackage.id;

        switch (file.integrityCheckResult) {
            case FileIntegrityResult.VALID:
                result = (<Tooltip
                    overlayInnerStyle={{textAlign: "left"}}

                    title={intlMessage("package-detail.file-integrity-check-result-tooltip", {
                        result: intlMessage("package-detail.file-integrity-check-result.VALID"),
                        checkedAt: file.integrityCheckDone ? FormatUtils.formatDate(file.integrityCheckDone) : ""
                    })}>
                    <CheckCircleFilled className={"color-green"}/>
                </Tooltip>);
                break;
            case FileIntegrityResult.INVALID:
                result = (<Tooltip
                    overlayInnerStyle={{textAlign: "left"}}
                    title={intlMessage("package-detail.file-integrity-check-result-tooltip", {
                        result: intlMessage("package-detail.file-integrity-check-result.INVALID"),
                        checkedAt: file.integrityCheckDone ? FormatUtils.formatDate(file.integrityCheckDone) : ""
                    })}>
                    <ExclamationCircleFilled className={"color-red"}/>
                </Tooltip>);
                break;
            case FileIntegrityResult.INACCESSIBLE:
                result = (<Tooltip
                    overlayInnerStyle={{textAlign: "left"}}
                    title={intlMessage("package-detail.file-integrity-check-result-tooltip", {
                        result: intlMessage("package-detail.file-integrity-check-result.INACCESSIBLE"),
                        checkedAt: file.integrityCheckDone ? FormatUtils.formatDate(file.integrityCheckDone) : ""
                    })}>
                    <ExclamationCircleFilled className={"color-orange"}/>
                </Tooltip>);
                break;
            case FileIntegrityResult.UNKNOWN:
            default:
                result = (<Tooltip
                    overlayInnerStyle={{textAlign: "left"}}
                    title={intlMessage("package-detail.file-integrity-check-result-tooltip", {
                        result: intlMessage("package-detail.file-integrity-check-result.UNKNOWN"),
                        checkedAt: file.integrityCheckDone ? FormatUtils.formatDate(file.integrityCheckDone) : ""
                    })}>
                    <QuestionCircleFilled className={"color-gray"}/>
                </Tooltip>);
                break;
        }


        let runIntegrityCheck = !file.integrityCheckStarted ? <OkLeftPopconfirm
            title={intlMessage("packages-detail.confirm-run-integrity-check-on-file", {fileName: file.name ? file.name : file.id})}
            onConfirm={() => checkPackageAndLoginPassword(() => onRunFileIntegrityCheckConfirm(file))}
            okText={intlMessage("common.yes")}
            cancelText={intlMessage("common.no")}>
            <a className={"button"} title={intlMessage("packages-detail.run-integrity-check-on-file")}>
                <SyncOutlined/>
            </a>
        </OkLeftPopconfirm> : <a className={"button"}><SyncOutlined spin={true}/></a>;

        if (file.hash) {
            return (
                <>
                    <Space>
                        {file.integrityCheckDone && <>{result}</>}

                        {!file.integrityCheckDone && <Tooltip
                            overlayInnerStyle={{textAlign: "left"}}
                            title={intlMessage("package-detail.file-integrity-check-result-tooltip", {
                                result: intlMessage("package-detail.file-integrity-check-result.UNKNOWN"),
                                checkedAt: file.integrityCheckDone ? FormatUtils.formatDate(file.integrityCheckDone) : ""
                            })}>
                            <QuestionCircleFilled className={"color-gray"}/>
                        </Tooltip>}

                        {integrityCheckAllowed() && runIntegrityCheck}
                    </Space>
                    <br/>
                    <span className={"file-hash"}>
                        SHA256:&nbsp;
                        <CopyToClipboard text={file.hash!} onCopy={() => message.info(intlMessage("package-detail.copied-to-clipboard"))}>
                            <a title={intlMessage("package-detail.copy-link-title")} className={"copy-link copy-link-hash"}>
                                {file.hash}
                            </a>
                        </CopyToClipboard>
                    </span>
                </>
            );
        }
    }

    function packageFileSorter(prev: any, next: any) {
        if (prev < next) {
            return prev > next ? 1 : -1;
        } else {
            return prev > next ? 1 : 0;
        }
    }

    function onRunFileIntegrityCheckConfirm(file: PackageFile) {
        packageService.checkFileIntegrity(file, downloadTokenInfo.current?.downloadToken).then(() => {
            message.success(intlMessage("packages-detail.file-integrity-check-started", {fileName: file.name}));

            loadPackage();
        });
    }

    function onRunPackageIntegrityCheckConfirm(aPackage: Package) {
        packageService.checkPackageIntegrity(aPackage, downloadTokenInfo.current?.downloadToken).then(() => {
            message.success(intlMessage("packages-detail.integrity-check-on-package-started", {packageName: aPackage.name ? aPackage.name : aPackage.id}));

            loadPackage();
        });
    }

    function packageFileDownloadCountSorter(prev: PackageFile, next: PackageFile) {
        const prevUserTotal = (prev.downloadCounter?.anonymousUsersTotal ?? 0) + (prev.downloadCounter?.registeredUsersTotal ?? 0);
        const nextUserTotal = (next.downloadCounter?.anonymousUsersTotal ?? 0) + (next.downloadCounter?.registeredUsersTotal ?? 0);

        if (prevUserTotal > nextUserTotal) {
            return 1;
        } else if (prevUserTotal < nextUserTotal) {
            return -1;
        } else {
            return 0;
        }
    }

    function onDownloadFileModalCancel() {
        resetDownloadFileModalState();
    }

    function onDownloadFileModalOk() {
        resetDownloadFileModalState();
    }

    function resetDownloadFileModalState() {
        setDownloadFileModal({
            visible: false,
            url: "",
            objectId: "",
            objectType: DownloadObjectType.FILE,
            packageDownloadToken: ""
        });
    }

    function renderPackageState() {
        const workflowState = intlMessage("PackageWorkflowState." + aPackage.workflowState);
        const checkState = intlMessage("PackageCheckState." + aPackage.checkState);

        let resultState;

        if (aPackage.workflowState === PackageWorkflowState.REQUESTED || aPackage.workflowState === PackageWorkflowState.UPLOADING) {
            resultState = workflowState;
        } else {
            resultState = workflowState + " - " + checkState;
        }

        return (
            `${resultState}`
        )
    }

    function renderPackageIntegrity() {

        let packageIntegrityCheckRunning = false;
        for (const file of aPackage.files!) {
            if (!file.contentDeleted) {
                if (file.integrityCheckStarted) {
                    packageIntegrityCheckRunning = true;
                }
            }
        }

        let result;
        switch (aPackage.dataIntegrity) {
            case PackageDataIntegrity.VALID:
                result = (<>
                    <CheckCircleFilled className={"color-green"}/>
                    {intlMessage("package-detail.package-integrity.VALID")}
                </>);
                break;
            case PackageDataIntegrity.INVALID:
                result = (<>
                    <ExclamationCircleFilled className={"color-red"}/>
                    {intlMessage("package-detail.package-integrity.INVALID")}
                </>);
                break;
            case PackageDataIntegrity.INACCESSIBLE:
                result = (<>
                    <ExclamationCircleFilled className={"color-orange"}/>
                    {intlMessage("package-detail.package-integrity.INACCESSIBLE")}
                </>);
                break;
            case PackageDataIntegrity.UNKNOWN:
            default:
                result = (<>
                    <QuestionCircleFilled className={"color-gray"}/>
                    {intlMessage("package-detail.package-integrity.UNKNOWN")}
                </>);
                break;
        }

        let runIntegrityCheck = !packageIntegrityCheckRunning ? <OkLeftPopconfirm
            title={intlMessage("packages-detail.confirm-run-integrity-check-on-package", {packageName: aPackage.name ? aPackage.name : aPackage.id})}
            onConfirm={() => checkPackageAndLoginPassword(() => onRunPackageIntegrityCheckConfirm(aPackage))}
            okText={intlMessage("common.yes")}
            cancelText={intlMessage("common.no")}>
            <a className={"button"} title={intlMessage("packages-detail.run-integrity-check-on-package")}>
                <SyncOutlined/>
            </a>
        </OkLeftPopconfirm> : <a className={"button"}><SyncOutlined spin={true}/></a>;


        return (
            <Space>
                {result}
                {integrityCheckAllowed() && runIntegrityCheck}
            </Space>
        )
    }

    function canAddFilesToPackage() {
        // neni uzivatel ... zakazano
        if (!appContext.user) return false;

        // zasilka musi bych schvalena, aktivni, cista a povolene odesilani
        if (packageApproved() && aPackage.workflowState === PackageWorkflowState.ACTIVE &&
            (aPackage.checkState === PackageCheckState.CLEAN || aPackage.checkState === PackageCheckState.UNKNOWN) &&
            appContext.user.hasPermission(UserPermission.SEND_PACKAGE)) {

            // vlastni zasilka a povolene pridavani souboru
            if (ownPackage  && appContext.applicationConfig?.allowAddFilesToPackage) return true;

            // vlastni kooperativni zasilka
            if (ownPackage  && aPackage.flags?.some(flag => flag === PackageFlag.COOPERATIVE)) return true;

            // spolupracovnik na kooperativni zasilce
            if (aPackage.contributor) return true;
        }

        // jinak ... zakazano
        return false;

    }

    function canDeleteOrRenameFilesInPackage() {
        // neni uzivatel ... zakazano
        if (!appContext.user) return false;

        // zasilka musi bych schvalena, aktivni, cista a povolene odesilani
        if (packageApproved() && aPackage.workflowState === PackageWorkflowState.ACTIVE &&
            (aPackage.checkState === PackageCheckState.CLEAN || aPackage.checkState === PackageCheckState.UNKNOWN) &&
            appContext.user.hasPermission(UserPermission.SEND_PACKAGE)) {

            // vlastni zasilka a povolene mazani a prejmenovani souboru
            if (ownPackage  && appContext.applicationConfig?.allowDeleteFilesFromPackage) return true;

            // vlastni kooperativni zasilka
            if (ownPackage  && aPackage.flags?.some(flag => flag === PackageFlag.COOPERATIVE)) return true;

            // spolupracovnik na kooperativni zasilce
            if (aPackage.contributor) return true;
        }

        // jinak ... zakazano
        return false;


    }

    function renderPackageAccess() {
        return intlMessage("package.access-type." + aPackage.accessType);
    }

    function renderFileUploaded(record: any, file: PackageFile) {
        return FormatUtils.formatDate(record);
    }

    function renderFileFlags(record: any, file: PackageFile) {

        return (
            <div className={"file-flags"}>
                {file.flags!.map((flag) => {
                    const key = "" + file.id + flag;

                    switch (flag) {
                        case FileFlag.OVERSIZE:
                            return <ColumnHeightOutlined key={key} className={"danger"} title={intlMessage("package-detail.file.flags.OVERSIZE")}/>
                        case FileFlag.ENCRYPTED_CONTENT:
                            return <FileUnknownFilled key={key} className={"danger"} title={intlMessage("package-detail.file.flags.ENCRYPTED_CONTENT")}/>
                        case FileFlag.DLP_MATCHED:
                            return "";
                        default:
                            return "";
                    }
                })}

                {file.cdrFiles && file.cdrFiles.length > 0 && isFileDownloadAllowed(file) &&
                    (
                        (aPackage.workflowState === PackageWorkflowState.ACTIVE || aPackage.workflowState === PackageWorkflowState.DELETED) &&
                        !file.contentDeleted && !file.cdrFiles[0].deleted ?
                            <a href={`/download-file/${file.cdrFiles[0].id}`}
                               onClick={e => {
                                   e.preventDefault();

                                   checkPackageAndLoginPasswordForDownload(e, `/download-file/${file.cdrFiles![0].id}`, file.id!, DownloadObjectType.FILE);
                               }}>
                                <FilePdfFilled className={"info"} title={intlMessage("package-detail.list.flags.CDR")}/>
                            </a>
                            : <FilePdfFilled className={"info"} title={intlMessage("package-detail.list.flags.CDR")}/>
                    )

                }
            </div>
        );
    }

    function renderFileDetail(record: PackageFile) {
        return (
            <div>
                <Row>
                    <Col xs={4} sm={4} lg={4}>
                        {intlMessage("package-detail.file.id")}
                    </Col>
                    <Col xs={20} sm={20} lg={20}>

                        <CopyToClipboard text={record.id!} onCopy={() => message.info(intlMessage("package-detail.copied-to-clipboard"))}>
                            <a title={intlMessage("package-detail.copy-link-title")} className={"copy-link copy-link-hash"}>
                                {record.id}
                            </a>
                        </CopyToClipboard>
                    </Col>
                </Row>
            </div>
        )
    }

    function renderArchive() {

        // stahovani souboru z odeslanych zasilek
        if (ownPackage && aPackage.accessType !== PackageAccessType.BRIEFCASE) {
            if (applicationConfig?.allowDownloadSentFiles === "NO") {
                return;
            }
            if (applicationConfig?.allowDownloadSentFiles === "PERMISSION_BASED") {
                if (!appContext.user?.hasPermission(UserPermission.ALLOW_DOWNLOAD_SENT_FILES)) {
                    return;
                }
            }
        }

        // omezeni stahovani z backendu
        if (aPackage.downloadFilesAllowed === false) {
            return;
        }

        const anyCleanFile = _.some(aPackage.files, (file) => file.state === "CLEAN");

        const cleanOrQuarantinedAndAllowed = aPackage.checkState === PackageCheckState.CLEAN || (aPackage.checkState === PackageCheckState.QUARANTINED && applicationConfig!.allowDownloadCleanFilesFromQuaratine);

        const anyFileUnchecked = _.some(aPackage.files, (file) => file.state === "WAITING" || file.state === "UPLOADING");
        const anyFileRechecked = _.some(aPackage.files, (file) => {
            return _.some(file.fileChecks, fileCheck => fileCheck.checkResult === FileCheckResult.NONE)
        });

        if (aPackage.workflowState === PackageWorkflowState.ACTIVE && anyCleanFile && cleanOrQuarantinedAndAllowed) {
            const someFileIsSmallerThanMax = aPackage.files?.some(file => file.size <= applicationConfig!.maxFileSizeInArchive);

            return (
                <div style={{textAlign: "center"}}>
                    <p>
                        {intlMessage("package-detail.archive.title-text", {maxSize: FormatUtils.formatBytes(applicationConfig!.maxFileSizeInArchive)})}
                    </p>
                    {(anyFileUnchecked || anyFileRechecked) &&
                        <p>
                            <Alert type={"warning"} showIcon={false} message={intlMessage("package-detail.archive.some-files-rechecked-text")} style={{marginBottom: 12}}/>
                        </p>
                    }
                    <p>
                        {intlMessage("package-detail.archive.files-text")}
                    </p>

                    {
                        !someFileIsSmallerThanMax &&

                        <p>
                            {intlMessage("package-detail.archive.title-text-all-files-large", {maxSize: FormatUtils.formatBytes(applicationConfig!.maxFileSizeInArchive)})}
                        </p>
                    }

                    {someFileIsSmallerThanMax && renderArchiveDownload()}

                    {
                        appContext.user &&

                        <p>
                            {intlMessage("package-detail.download-count")}: {renderDownloadCounter(aPackage.archiveDownloadCounter)}
                        </p>
                    }
                </div>
            )
        } else {
            return "";
        }
    }

    function renderArchiveDownload() {
        const isPublicPackage = aPackage.accessType === PackageAccessType.PUBLIC

        const fileArchivePath = `/download-archive/${aPackage.id}`;
// ${limitAccessToken ? ('?' + limitAccessToken + '=' + limitAccessToken) : ''}
        let downloadArchiveButton =
            <a className="ant-btn ant-btn-primary btn-big"
               href={fileArchivePath}
               onClick={e => {
                   e.preventDefault();

                   checkPackageAndLoginPasswordForDownload(e, fileArchivePath, packageId, DownloadObjectType.ACHIVE);
               }}>
                {intlMessage("package-detail.archive.download-action")}
            </a>;

        if (!isPublicPackage && !appContext?.user?.hasPermission(UserPermission.ALLOW_DOWNLOAD_WITHOUT_LOGIN)) {
            downloadArchiveButton = <a className="ant-btn ant-btn-primary btn-big" onClick={() => promptForLoginPassword(fileArchivePath, aPackage.id!, DownloadObjectType.ACHIVE)}>{intlMessage("package-detail.archive.download-action")}</a>;
        }

        const archiveSize = aPackage.fileList?.filter(file => file.size <= applicationConfig!.maxFileSizeInArchive)
            .map(file => file.size)
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

        return (
            <div style={{textAlign: "center"}}>
                <p>
                    {downloadArchiveButton}
                </p>

                <p>
                    {intlMessage('package-detail.archive.size_description', {size: FormatUtils.formatBytes(archiveSize)})}
                </p>
            </div>
        );
    }

    function isFileDownloadAllowed(file: PackageFile) {
        let downloadAllowed = false;

        if (aPackage.workflowState === PackageWorkflowState.ACTIVE) {
            if (aPackage.checkState === PackageCheckState.CLEAN) {
                if (file.state === "CLEAN") {
                    downloadAllowed = true;
                }
            } else {
                if (file.state === "CLEAN" && applicationConfig!.allowDownloadCleanFilesFromQuaratine) {
                    downloadAllowed = true;
                }
            }
        }

        // smazane soubory
        if (file.deleted) {
            downloadAllowed = false;
        }

        // omezeni stahovani z backendu
        if (aPackage.downloadFilesAllowed === false) {
            downloadAllowed = false;
        }

        return downloadAllowed;

    }

    function renderFileName(record: any, file: PackageFile) {
        const filePath = `/download-file/${file.id}`;

        if (isFileDownloadAllowed(file)) {
            return (
                <>
                    <Space>
                        <a href={filePath}
                           onClick={e => {
                               e.preventDefault();

                               checkPackageAndLoginPasswordForDownload(e, filePath, file.id!, DownloadObjectType.FILE);
                           }}>
                            {file.name}
                        </a>
                    </Space>

                    <br/>
                    {renderFileFlags(record, file)}
                </>
            )
        } else {
            return (
                <>
                    <span>{file.name}</span>
                    <br/>
                    {renderFileFlags(record, file)}
                </>
            )
        }
    }

    /**
     * Zobrazí dialog s výzvou pro zadání hesla zásilky (pro heslem šifrované zásilky).
     */
    function checkPackageAndLoginPassword(callback: () => void) {
        const packagePasswordRequired = aPackage.dataEncryptedWithPassword && (downloadTokenInfo.current === undefined || !isDownloadTokenInfoValid(downloadTokenInfo.current));

        if (!packagePasswordRequired) {
            callback();

            return;
        }

        promptForPackagePassword((tokenInfo, rememberPassword) => {
            downloadTokenInfo.current = tokenInfo;

            setPackagePasswordModal(prevState => ({...prevState, visible: false}));

            if (rememberPassword) {
                localStorageService.setIndexedItem(LocalStorageNamespace.PackagePassword, packageId, JSON.stringify(tokenInfo));
            }

            callback();
        });
    }

    /**
     * Zobrazí dialog s výzvou pro zadání hesla zásilky (pro heslem šifrované zásilky) anebo hesla uživatele (pokud jde o neveřejnou zásilku a uživatel nemá právo stahovat bez ověření heslem).

     * @param e událost kliknutí na odkaz s URL souboru
     * @param objectPath cesta k objektu ke stažení
     * @param objectId ID objektu
     * @param objectType typ objektu
     */
    function checkPackageAndLoginPasswordForDownload(e: MouseEvent, objectPath: string, objectId: string, objectType: DownloadObjectType) {
        if (packageRequiresPassword && (downloadTokenInfo.current === undefined || !isDownloadTokenInfoValid(downloadTokenInfo.current))) {
            setModelState(ModelState.PasswordRequired);

            downloadTokenInfo.current = undefined;

            return;
        }

        const packagePasswordRequired = aPackage.dataEncryptedWithPassword && (downloadTokenInfo.current === undefined || !isDownloadTokenInfoValid(downloadTokenInfo.current));

        const loginPasswordRequired = aPackage?.accessType !== PackageAccessType.PUBLIC && !appContext?.user?.hasPermission(UserPermission.ALLOW_DOWNLOAD_WITHOUT_LOGIN)

        if (!packagePasswordRequired && !loginPasswordRequired) {
            downloadFileViaAnchor(objectPath, {token: downloadTokenInfo.current?.downloadToken, limitAccessToken: limitAccessToken.current});

            return;
        }

        if (packagePasswordRequired) {
            promptForPackagePassword((tokenInfo, rememberPassword) => {
                downloadTokenInfo.current = tokenInfo;

                setPackagePasswordModal(prevState => ({...prevState, visible: false}));

                if (rememberPassword) {
                    localStorageService.setIndexedItem(LocalStorageNamespace.PackagePassword, packageId, JSON.stringify(tokenInfo));
                }

                if (loginPasswordRequired) {
                    promptForLoginPassword(objectPath, objectId, objectType, downloadTokenInfo.current.downloadToken);
                } else {
                    downloadFileViaAnchor(objectPath, {token: downloadTokenInfo.current.downloadToken, limitAccessToken: limitAccessToken.current});
                }
            });
        } else {
            if (loginPasswordRequired) {
                promptForLoginPassword(objectPath, objectId, objectType, downloadTokenInfo.current?.downloadToken);
            } else {
                downloadFileViaAnchor(objectPath, {token: downloadTokenInfo.current?.downloadToken, limitAccessToken: limitAccessToken.current});
            }
        }
    }

    function promptForPackagePassword(onDownloadTokenAcquired: (token: DownloadTokenInfo, rememberPassword: boolean) => void) {
        setPackagePasswordModal(prevState => ({
            ...prevState,
            visible: true,
            onDownloadTokenAcquired
        }));
    }

    function promptForLoginPassword(url: string, objectId: string, objectType: DownloadObjectType, packageDownloadToken?: string) {
        setDownloadFileModal({
            visible: true,
            url,
            objectId,
            objectType,
            packageDownloadToken
        });
    }

    function extractHostname(url: string) {
        var hostname;
        //find & remove protocol (http, ftp, etc.) and get hostname

        if (url.indexOf("//") > -1) {
            hostname = url.split('/')[2];
        } else {
            hostname = url.split('/')[0];
        }

        //find & remove port number
        hostname = hostname.split(':')[0];
        //find & remove "?"
        hostname = hostname.split('?')[0];

        return hostname;
    }

    function adfsLoginRedirect(idToken: string) {
        window.location.replace(applicationConfig!.adfsConfig![0].redirectUri + "?id_token=" + idToken);
    }

    function renderAccessDenied() {
        // presmerujeme na package jen kdyz je uzivatel neprihlaseny
        if (!appContext.user) {
            Cookies.set("Load-package", window.location.pathname, {path: "/", expires: new Date(Date.now() + (60 * 1000))});
        } else {
            Cookies.remove("Load-package", {path: "/"});
        }

        if (window.location.search && window.location.search === "?autologin-adfs") {
            const adfsConfigs = applicationConfig!.adfsConfig;
            const protocol = process.env.NODE_ENV === 'development' ? "http:" : "https:";

            if (adfsConfigs && adfsConfigs[0]) {
                const adalConfig: AdalConfig = {
                    tenant: 'adfs',
                    clientId: adfsConfigs[0].clientId,
                    redirectUri: protocol + "//" + window.location.host + adfsConfigs[0].redirectUri,
                    instance: adfsConfigs[0].instance,
                    cacheLocation: 'localStorage',
                    extraQueryParameter: 'scope=openid&response_mode=form_post',
                }

                const authContext = new AuthenticationContext(adalConfig);

                authContext.login();
            }
        }

        return (
            <Row justify={"space-around"} align={"middle"} className={"login-form"}>
                <Col id={"login-form"} style={{textAlign: "center"}}>
                    {appContext.user ?
                        <>
                            <h2 className={"package-access-problem"}>{intlMessage("access-denied")}</h2>
                            {serverErrorMessage && <h4 className={"package-access-problem"}>{intlMessage(serverErrorMessage)}</h4>}
                            <CloseCircleFilled className={"bigIcon"}/>
                        </>
                        :
                        <>
                            {serverErrorMessage === "package_access_limit_exhausted" ?
                                <>
                                    <h2 className={"package-access-problem"}>{intlMessage("access-denied")}</h2>
                                    {serverErrorMessage && <h4 className={"package-access-problem"}>{intlMessage(serverErrorMessage)}</h4>}
                                    <CloseCircleFilled className={"bigIcon"}/>
                                </> :
                                <>
                                    <h2 className={"package-access-problem"}>{intlMessage("access-denied-login-required")}</h2>
                                    {serverErrorMessage && <h4 className={"package-access-problem"}>{intlMessage(serverErrorMessage)}</h4>}

                                    <Link to={anonymousRoutesMap.Login.path}
                                          id={"loginLink"}>{intlMessage("header.login")}</Link>
                                </>
                            }
                        </>

                    }

                </Col>
            </Row>
        );
    }

    function renderNotFound() {
        return (
            <Row justify={"space-around"} align={"middle"} className={"login-form"}>
                <Col id={"login-form"} style={{textAlign: "center"}}>
                    <h1 className={"package-access-problem"}>{intlMessage("not-found")}</h1>
                    <CloseCircleFilled className={"bigIcon"}/>
                </Col>
            </Row>
        );
    }

    function renderTooManyRequests() {
        return (
            <Row justify={"space-around"} align={"middle"} className={"login-form"}>
                <Col id={"login-form"} style={{textAlign: "center"}}>
                    <h2 className={"package-access-problem"}>{serverErrorMessage}</h2>
                    <CloseCircleFilled className={"bigIcon"}/>
                </Col>
            </Row>
        );
    }

    function renderApproverName(approver: User | Approver | undefined) {
        let fullName;

        let formatedApproverName = "";

        if (!approver) return "";

        fullName = approver.firstName || approver.lastName ? approver.lastName + " " + approver.firstName : undefined;

        if (fullName) {
            formatedApproverName = fullName + (approver.email ? " (" + approver.email + ")" : "");
        } else {
            formatedApproverName = approver.email!;
        }

        return (<Space>{formatedApproverName}{approver.deleted ? <Tooltip title={intlMessage("deleted-approver")}><InfoCircleFilled style={{opacity: 0.6}}/></Tooltip> : ""}</Space>);

    }

    function renderUploadedBy(uploadedBy: User) {
        return uploadedBy ? uploadedBy.email : "";
    }

    function renderSender() {
        let senderName;
        let senderEmail;

        if (!aPackage) {
            return "";
        }

        if (aPackage.fromRegisteredUser && aPackage.workflowState !== PackageWorkflowState.REQUESTED) {
            if (aPackage.sender) {
                senderName = aPackage.sender.firstName || aPackage.sender.lastName ? aPackage.sender.lastName + " " + aPackage.sender.firstName : undefined;
                senderEmail = aPackage.sender.email;
            }
        } else if (aPackage.anonymousSenderName || aPackage.anonymousSenderEmail) {
            if (aPackage.anonymousSenderEmail) {
                senderEmail = aPackage.anonymousSenderEmail;

                if (aPackage.anonymousSenderName) {
                    senderName = aPackage.anonymousSenderName;
                }
            } else {
                senderEmail = aPackage.anonymousSenderName;
            }

            if (aPackage.anonymousSenderName) {
                senderName = aPackage.anonymousSenderName;
            }
        } else {
            senderEmail = intlMessage("packages-inbox-outbox.unknown-sender");
        }

        const unverifiedEmail = aPackage.fromRegisteredUser ? "" : <Tooltip title={intlMessage("unverified-email")}><WarningFilled/></Tooltip>;

        if (senderName) {
            return (<Tooltip title={senderName}><Tag>{senderEmail}{unverifiedEmail}</Tag></Tooltip>);
        } else {
            return (<Tag>{senderEmail}{unverifiedEmail}</Tag>);
        }
    }

    function renderRecipients(contributors: boolean) {
        if (!aPackage) {
            return "";
        }

        if (!aPackage.internalRecipients && !aPackage.externalRecipients) {
            return "";
        }

        return (
            <span className="package-recipients">
                {aPackage.sender && appContext.user && appContext.user.email === aPackage.sender.email && aPackage.internalRecipients
                    ? aPackage.internalRecipients.filter(recipient => recipient.contributor === contributors).map((value, index) => (
                        <span key={value.email}>
                            <Tooltip title={value.name}>
                                <span>
                                <SmartTag value={value.email}
                                          title={intlMessage("package.recipient.delete-confirm", {email: value.email})}
                                          onClose={() => handleDeleteInternalRecipient(value)}
                                          okText={intlMessage("common.yes")}
                                          cancelText={intlMessage("common.no")}
                                          closable={true}
                                          confirm={true} style={{marginRight: "4px"}}/>
                                </span>
                            </Tooltip>
                        </span>
                    ))
                    : aPackage.internalRecipients!.filter(recipient => recipient.contributor === contributors).map((value) => (
                        <span key={value.email}>
                            <SmartTag value={value.email} closable={false} style={{marginRight: "4px"}}/>
                        </span>
                    ))
                }
                {aPackage.externalRecipients && !contributors && aPackage.externalRecipients.map((value) => (
                    <span key={value.email}>
                        <Tooltip title={value.name}>
                            <Tag style={{marginRight: "4px"}}>{value.email}</Tag>
                        </Tooltip>
                    </span>
                ))}
            </span>
        )
    }

    function handleDeleteContributor(index: number) {

    }

    function handleDeleteInternalRecipient(recipientToDelete: InternalRecipient) {

        const recipients = aPackage.internalRecipients;

        const index = recipients!.indexOf(recipientToDelete, 0);

        if (index > -1) {
            recipients!.splice(index, 1)
        }

        setAPackage(prevState => {
            return ({
                ...prevState,
                internalRecipients: recipients
            });
        })

        packageService.deleteInternalRecipient(aPackage, recipientToDelete).then(value => {
            message.success(intlMessage("recipient-was-deleted", {recipient: recipientToDelete.email}))
        });
    }

    function renderSize(size: number) {
        return FormatUtils.formatBytes(size);
    }

    function renderDownloadCounter(counter?: DownloadCounter) {
        if (counter === undefined) {
            return;
        }

        return (
            <>
                <Tooltip
                    overlayInnerStyle={{textAlign: "center"}}
                    title={intlMessage("package-detail.download-count-tooltip", {
                        anonymousTotal: counter.anonymousUsersTotal,
                        registeredUsersTotal: counter.registeredUsersTotal,
                        adminsTotal: counter.adminsTotal
                    })}>

                    {counter.anonymousUsersTotal} / {counter.registeredUsersTotal}

                    {counter.adminsTotal > 0 && <span> / {counter.adminsTotal}</span>}

                </Tooltip>
            </>
        );
    }

    function onPasswordSubmit(password: string, rememberPassword: boolean) {
        return loadPackage({password, rememberPassword});
    }

    function onAccessConfirm() {
        return loadPackage();
    }

    function loadPackage(options?: { password?: string, rememberPassword?: boolean }): Promise<any> {

        return packageService.get(packageId, options?.password, downloadTokenInfo.current?.downloadToken, limitAccessToken.current).then(
            (aPkg: Package) => {
                if (aPkg.files && aPkg.files.length > 0) {

                    // seradit soubory - podezrele prvni
                    const cleanFiles: PackageFile[] = [];
                    const maliciousFiles: PackageFile[] = [];

                    const cdrCount: any = {};
                    const cdrFiles: any = {};

                    for (const file of aPkg.files) {
                        if (file.state === "CLEAN") {
                            cleanFiles.push(file);
                        } else {
                            maliciousFiles.push(file);
                        }

                        file.cdrCount = 0;

                        if (file.flags?.includes(FileFlag.CDR) && !file.contentDeleted) {
                            if (!cdrCount[file.originalFile!]) {
                                cdrCount[file.originalFile!] = 1;
                                cdrFiles[file.originalFile!] = [];
                            } else {
                                cdrCount[file.originalFile!]++;
                            }
                            cdrFiles[file.originalFile!].push(file);
                        }
                    }

                    aPkg.files = maliciousFiles.concat(cleanFiles);

                    aPkg.deletedFileList = [];
                    aPkg.uncleanFileList = [];
                    aPkg.fileList = [];

                    for (const file of aPkg.files) {

                        if (cdrCount[file.id!]) {
                            file.cdrCount = cdrCount[file.id!];
                            file.cdrFiles = cdrFiles[file.id!];
                        }

                        if (file.deleted) {
                            aPkg.deletedFileList.push(file);
                        } else if (file.state === "UNCLEAN") {
                            aPkg.uncleanFileList.push(file);
                        } else {
                            aPkg.fileList.push(file);
                        }
                    }
                }

                if (aPkg.downloadTokenInfo) {
                    downloadTokenInfo.current = aPkg.downloadTokenInfo;

                    if (options?.rememberPassword) {
                        localStorageService.setIndexedItem(LocalStorageNamespace.PackagePassword, packageId, JSON.stringify(downloadTokenInfo.current));
                    }
                }

                if (aPkg.note) {
                    const tmpNote = aPkg.note.split("------");

                    aPkg.noteFrom = tmpNote[0];
                    aPkg.noteTo = tmpNote[1];
                }

                setAPackage(aPkg);
                setModelState(ModelState.Loaded);

                const ownPkg = !!appContext.user && !!aPkg.sender && (appContext.user.email === aPkg.sender.email);
                setOwnPackage(ownPkg);

                // jsem interni prijemce?
                aPkg.internalRecipients && aPkg.internalRecipients?.forEach(recipient => {
                    if (recipient.email === appContext.user?.email) {
                        setRecipientPackage(true);
                    }
                });

                if (appContext.user && (!aPkg.userFlags || !aPkg.userFlags!.viewed)) {
                    packageService.setViewed(aPkg);
                }

                appContext.disableLangSelector = !ownPkg && aPkg.limitAccessCount! > 0;

                return aPkg;
            },
            reason => {
                if (reason.response) {
                    if (reason.response.status === 400) {
                        setPackageRequiresPassword(true);
                        setModelState(ModelState.PasswordRequired);

                    } else if (reason.response.status === 402) {
                        limitAccessToken.current = reason.response.headers['x-sofie-limit-access-token'];
                        setModelState(ModelState.LimitAccessConfirmRequired);

                    } else if (reason.response.status === 404) {
                        setModelState(ModelState.NotFound);

                    } else if (reason.response.status === 429) {
                        setModelState(ModelState.TooManyRequests);
                        setServerErrorMessage(reason.response.data);

                    } else if (reason.response.status === 403) {

                        if (reason.response.headers['x-sofie-response-reason']) {
                            setModelState(ModelState.AccessDenied);
                            setServerErrorMessage(reason.response.headers['x-sofie-response-reason']);

                        } else {

                            if (modelState === ModelState.PasswordRequired) {
                                setEnteredInvalidPassword(true);
                                setServerErrorMessage(reason.response.data);

                            } else {
                                setModelState(ModelState.AccessDenied);
                                setServerErrorMessage(reason.response.data);

                            }
                        }
                    }
                }
            });
    }

    function createNextWorkflowStepMessage(): string {
        if (!aPackage) {
            return '';
        }

        if (aPackage.workflowState === PackageWorkflowState.ACTIVE) {
            if (aPackage.checkState === PackageCheckState.CLEAN) {
                return intlMessage("workflow.step.active", {datetime: FormatUtils.formatOnDateAtTime(intl, aPackage.cleanExpiration!)});
            } else if (aPackage.checkState === PackageCheckState.QUARANTINED) {
                return intlMessage("workflow.step.active", {datetime: FormatUtils.formatOnDateAtTime(intl, aPackage.quarantinedExpiration!)});
            }
        }

        return '';
    }

}

export default PackageDownload;
